
  import AiSearch from 'models/ai_search'
  import KnowledgeSource from 'models/knowledge_source'

  import { CheckCircleIcon, CloudDownloadIcon, RefreshIcon, XCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      host: [AiSearch, KnowledgeSource]

    components:
      CheckCircleIcon: CheckCircleIcon
      CloudDownloadIcon: CloudDownloadIcon
      RefreshIcon: RefreshIcon
      XCircleIcon: XCircleIcon
