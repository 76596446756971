
  import ContentEditableSpan from 'models/content_editable_span.coffee'
  import ExpressionEditor from './expression_editor.vue'
  import Contenteditable from 'mixins/contenteditable.coffee'
  import { Badge, Button } from 'components/generic'
  import { ExclamationCircleIcon } from '@heroicons/vue/solid'

  export default
    mixins: [Contenteditable]

    props:
      modelValue: String
      node: Object
      textSize:
        type: String
        default: 'text-base'
      placeholder:
        type: String
        default: ''
      height:
        type: String
        default: 'md'
      maxLength:
        type: Number
        default: null
      textAlign:
        type: String
        default: 'left'
      hideButton:
        type: Boolean
        default: false

    emits: ['update:modelValue', 'remove', 'focus', 'blur']

    data: ->
      text: @modelValue
      expressionEditorRefs: []
      possibleVariables: null
      debounceTimeout: null
      editing: false
      textLength: 0
      buttonActive: false

    computed:
      computedText: ->
        @parts.map (part) =>
          if part.type == 'expression'
            "${#{part.text}}"
          else
            part.text.replaceAll('​', '')
        .join('')
      heightClass: ->
        switch @height
          when 'sm' then 'py-1'
          else 'py-2'
      textAlignmentClass: ->
        if @textAlign == 'center' then 'text-center' else 'text-left'
      maxLengthBadgeClasses: ->
        if @textLength > @maxLength
          'bg-MercuryRed-50 text-MercuryRed-800 font-normal'
        else
          'bg-gray-100 text-gray-800 font-light'
      hasExpressions: ->
        @parts.some (part) -> part.type == 'expression'

    watch:
      modelValue: ->
        @text = @modelValue
        @buildParts()

    created: ->
      @possibleVariables = @$root.contextParameters.slice()
      if @node?.getVariables?
        @possibleVariables = (await @node.getVariables()).concat(@possibleVariables)
        @buildParts()
        @catchRevision()
      else
        @buildParts()
        @catchRevision()

    beforeUpdate: ->
      @expressionEditorRefs = []

    beforeUnmount: ->
      return if !@debounceTimeout?
      clearTimeout(@debounceTimeout)
      @updateParts()

    methods:
      setParts: (addParts) ->
        @parts = [new ContentEditableSpan(type: 'text', text: '​')]
        addParts()
        if @parts.length == 1 && @parts[0].text == '​'
          @parts = []
          @textLength = 0
        else
          @textLength = @parts[1..]
            .filter (p) -> p.type == 'text' && p.text != '​'
            .map (p) -> p.text.length
            .sum()
      addTextPart: (text) ->
        return if text.length == 0 || text == '​'
        @parts.push(new ContentEditableSpan(type: 'text', text: text))
      addEmptyTextPart: ->
        @parts.push(new ContentEditableSpan(type: 'text', text: '​'))
      buildParts: ->
        @setParts(=>
          charIndex = 0
          [...@text.matchAll(/\$\{(.*?)\}/g)].forEach (match) =>
            variableWithoutComponent = match[1].match(/^([A-Za-z0-9_\.]+)(#[A-Za-z0-9_\.]+)?$/)?[1]
            if @possibleVariables.map((v) -> v.reference).includes(variableWithoutComponent)
              precedingText = @text[charIndex...match.index]
              @addTextPart(precedingText)
              @parts.push(new ContentEditableSpan(type: 'expression', text: match[1]))
              charIndex += precedingText.length + match[0].length
            else
              text = @text[charIndex...(match.index + match[0].length)]
              @addTextPart(text)
              charIndex += text.length
          if charIndex < @text.length
            @addTextPart(@text[charIndex..])
          @addEmptyTextPart() if @parts.last?.type == 'expression'
        )
      updateText: (event) ->
        return if event.target != @$refs.editable
        @textLength += event.data?.length || 0
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(@updateParts, 1000)
      updateParts: ->
        @selection = document.getSelection()
        # hier die Expressions auslesen:
        @setParts(=>
          charIndex = 0
          @resetCaretPosition()
          @setCaretPositionDirection()
          @$refs.editable.childNodes.forEach (node) =>
            @setCaretPosition(node, charIndex)
            switch node.nodeName
              when '#text'
                @addTextPart(node.textContent)
                charIndex += node.textContent.length if node.textContent != '​' # ignore zero-width space for caret position
              when 'SPAN'
                @parts.push(new ContentEditableSpan(type: 'expression', text: node.dataset['text']))
                charIndex += @getText(node).length
              when 'BR' # this is like Firefox handles linebreaks
                @addTextPart("\n")
          @addEmptyTextPart() if @parts.last?.type == 'expression'
        )
        @catchRevision()
        @updateWithDisplay()
      onSelectionchange: ->
        @selection = document.getSelection()
        if @selection.focusNode?
          @focusNode = @selection.focusNode
          @focusOffset = @selection.focusOffset
        isDirectlyInsideEditable = @$refs.editable == @selection.anchorNode?.parentElement == @selection.focusNode?.parentElement
        selectionIsCollapsed = @selection.isCollapsed
        setTimeout(=>
          @buttonActive = isDirectlyInsideEditable && selectionIsCollapsed ||
            @$refs.editable == document.activeElement && @text.length == 0
        , 500 # avoid button disappearing before relasing mouse button
        )
      insert: ->
        clearTimeout(@debounceTimeout)
        newExpressionIndex = 0
        @setParts(=>
          Array.from(@$refs.editable.childNodes).forEach (node) =>
            if node == @focusNode
              nodeText = @getText(node)
              @addTextPart(nodeText[0...@focusOffset])
              @parts.push(new ContentEditableSpan(type: 'expression', text: ''))
              newExpressionIndex = @parts.length - 1
              @addTextPart(nodeText[@focusOffset..])
            else
              if node.nodeName == 'SPAN'
                @parts.push(new ContentEditableSpan(type: 'expression', text: node.dataset['text']))
              else
                @addTextPart(@getText(node))
          if @parts.filter((part) -> part.type == 'expression').length == 0
            @parts.push(new ContentEditableSpan(type: 'expression', text: ''))
            newExpressionIndex = @parts.length - 1
          @addEmptyTextPart() if @parts.last?.type == 'expression'
        )
        @showText = false
        @$nextTick =>
          @showText = true
          @$nextTick =>
            @expressionEditorRefs[newExpressionIndex].edit()
      remove: (index) ->
        @parts.splice(index, 1)
        @update()
      setExpressionEditorRef: (index, el) ->
        @expressionEditorRefs[index] = el if el?
      setExpression: (index, object) ->
        return if !object.text
        @update()
      update: ->
        @$emit('update:modelValue', @computedText)
      emitFocus: ->
        @editing = true
        @$emit('focus')
      emitBlur: ->
        setTimeout =>
          return if @$el.contains(document.activeElement)
          @editing = false
          @$emit('blur')
        , 100

    components:
      Badge: Badge
      Button: Button
      ExclamationCircleIcon: ExclamationCircleIcon
      ExpressionEditor: ExpressionEditor
