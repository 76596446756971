
  import Conversation from 'models/conversation'
  import DesktopNotifier from 'models/desktop_notifier'
  import TableConfiguration from 'models/table_configuration'
  import { alert, notifyError } from 'helpers'

  import Controls from './controls.vue'
  import ConversationInList from './conversation_in_list.vue'
  import { Spinner, TableConfigurator } from 'components/generic'
  import { ChevronLeftIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Conversation
      singleConversationId: String

    data: ->
      conversations: []
      currentConversation: @modelValue
      params: {pageSize: 15}
      loading: false
      finished: false
      threshold: 400 # in pixels (if end of inner container is nearer than threshold, new item will be loaded)
      conversationRefs: {}
      tableConfig: new TableConfiguration(
        columns: [
          {key: 'avatarAndChannel', label: t('inbox.conversations.avatarAndChannelIcon'), defaultShow: true}
          {key: 'lastMessage', label: t('inbox.conversations.lastMessage'), defaultShow: true}
          {key: 'lastMessageTime', label: t('inbox.conversations.lastMessageTime'), defaultShow: true}
          {key: 'assignment', label: t('inbox.assignment.assignedTo'), defaultShow: false}
          {key: 'waitTime', label: t('inbox.conversations.waitTime'), defaultShow: true}
        ]
      )

    watch:
      modelValue: ->
        @currentConversation = @modelValue

    computed:
      conversationLists: ->
        [
          label: t('inbox.date.today')
          id: 'today'
          list: @conversations.filter (conversation) -> conversation.isOfToday
        ,
          label: t('inbox.date.yesterday')
          id: 'yesterday'
          list: @conversations.filter (conversation) -> conversation.isOfYesterday
        ,
          label: t('inbox.date.thisWeek')
          id: 'this-week'
          list: @conversations.filter (conversation) -> conversation.isOfThisWeek
        ,
          label: t('inbox.date.before')
          id: 'before'
          list: @conversations.filter (conversation) -> conversation.isBeforeThisWeek
        ].filter (sublist) -> sublist.list.length > 0
      hideConversationListLabels: ->
        @conversationLists.length == 1 && @conversationLists[0].id == 'before' || @singleConversationId

    created: ->
      @initSettings()
      @$root.api.handlers.message.queued = @handleMessageEvent
      @$root.api.handlers.message.sent = @handleMessageEvent
      @$root.api.handlers.message.received = @handleMessageEvent
      @$root.api.handlers.user_info = @handleUserEvent
      @$root.api.handlers.context = @handleContextEvent
      @$root.api.handlers.conversation_notes = @handleNotesEvent
      @$root.api.handlers.conversation_handover = @handleHandoverEvent

    mounted: ->
      @$refs.scrollElement.addEventListener 'scroll', @check

    beforeUpdate: ->
      @conversationRefs = {}

    methods:
      initSettings: ->
        @$root.userSettings.conversations ||= {}
        @$root.userSettings.conversations.columns ||= {}
        @tableConfig.columns.forEach (col) =>
          if @$root.userSettings.conversations.columns[col.key]?
            @tableConfig.columnsByKey[col.key].show = @$root.userSettings.conversations.columns[col.key]
      saveSettings: ->
        @tableConfig.columns.forEach (col) =>
          @$root.userSettings.conversations.columns[col.key] = @tableConfig.columnsByKey[col.key].show
        @$emit('settings-changed')
      start: ->
        @conversations = []
        @loading = true
        @finished = false
        new Promise (resolve, reject) =>
          @$root.api.initFilter(@$root.filtersForApi)
            .then(@append)
            .then =>
              if @singleConversationId
                if @conversations[0]?
                  @setCurrentConversation(@conversations[0])
                else
                  await alert('Couldn’t load conversation.')
              resolve()
            .catch (e) => notifyError(e)
      load: -> # load new items
        new Promise (resolve, reject) =>
          if @finished
            resolve()
            return
          @loading = true
          @$root.api.loadConversations(@params)
            .then(@append)
            .then(resolve)
            .catch => setTimeout(@load, 10000)
      append: (data) ->
        newConversations = data.map (item) -> new Conversation(item)
        @conversations.push.apply(@conversations, newConversations)
        @finished = if data.finished? then data.finished else data.length < @params.pageSize
        @loading = false
        @params.beforeId = @conversations.last?.id
        new Promise (resolve, reject) =>
          Vue.nextTick =>
            @check().then(resolve)
      check: -> # infinite scroll: check whether new items should be loaded
        new Promise (resolve, reject) =>
          if @loading || @finished
            resolve()
            return
          if @$refs.scrollElement.scrollHeight - (@$refs.scrollElement.scrollTop + @$refs.scrollElement.offsetHeight) < @threshold
            @load().then(resolve)
          else
            resolve()
      setCurrentConversation: (conversation) ->
        conversation.load(@$root.api)
        @currentConversation = conversation
        @$emit('update:modelValue', @currentConversation)
      setCurrentConversationById: (conversationId) -> # external use
        conversation = @conversations.find (conversation) -> conversation.id == conversationId
        return if !conversation?
        @setCurrentConversation(conversation)
      updateFilters: ->
        @$emit('update-filters')
        @start()
      setConversationRef: (id, el) ->
        @conversationRefs[id] = el if el?

      # event handlers

      handleMessageEvent: (data) ->
        # find or create conversation
        conversation = @conversations.find (conv) -> conv.id == data.user.id
        if !conversation then conversation = @addNewConversation(data)
        newMessage = conversation.addMessage(data)
        # care for 'read' status
        if conversation.id == @$root.currentConversation?.id
          conversation.lastMessageReadByAnyAgent = true
          @$root.api.setMessageRead(newMessage)
        @conditionallyNotify(conversation, newMessage)
        # push conversation to top
        if conversation.lastMessageTime > @conversations[0].lastMessageTime
          @prependConversation(conversation)
      handleUserEvent: (data) ->
        conversation = @conversations.find (conv) -> conv.id == data.user.id
        return if !conversation
        conversation.update(
          user: data.user
          status: data.status
          typing: data.typing
        )
      handleContextEvent: (data) ->
        conversation = @conversations.find (conv) -> conv.id == data.user.id
        return if !conversation
        conversation.update(context: data.updatedContext)
      handleNotesEvent: (data) ->
        conversation = @conversations.find (conv) -> conv.id == data.user.id
        return if !conversation
        conversation.update(notes: data.notes)
      handleHandoverEvent: (data) ->
        conversation = @conversations.find (conv) -> conv.id == data.user.id
        if conversation?
          conversation.update(handoverSession: data.handoverSession)
        else
          conversation = @addNewConversation(data)
          conversation.loadLastMessage(@$root.api)
        @conditionallyNotify(conversation)
      addNewConversation: (data) ->
        conversation = new Conversation(data)
        conversation.load(@$root.api)
        @prependConversation(conversation)
        conversation
      prependConversation: (conversation) ->
        oldScrollTop = @$refs.scrollElement.scrollTop
        index = @conversations.findIndex (conv) -> conv.id == conversation.id
        if index == -1
          @conversations = [conversation].concat(@conversations)
        else
          @conversations = [conversation]
            .concat @conversations.slice(0, index)
            .concat @conversations.slice(index + 1)
        # if scroll position does not change
        conversationComponentEl = @conversationRefs[conversation.id]?.$el
        return if conversationComponentEl? && conversationComponentEl.offsetTop < oldScrollTop
        # otherwise keep vertical position of list:
        @$nextTick =>
          conversationComponentEl = @conversationRefs[conversation.id]?.$el
          return if !conversationComponentEl?
          if conversationComponentEl.offsetTop < @$refs.scrollElement.scrollTop
            @$refs.scrollElement.scrollTop = oldScrollTop + conversationComponentEl.offsetHeight
      conditionallyNotify: (conversation, message) ->
        return if message && message.origin != 'USER'
        return unless conversation.isInHandoverMode && conversation.handoverSession.assignedAgentId == @$root.agent.id && conversation.id != @$root.currentConversation?.id
        # @browserNotificationCount += 1
        # if @browserNotificationCount > 0
        #   document.title = "(#{@browserNotificationCount}) Mercury.ai"
        # else
        #   document.title = "Mercury.ai"
        DesktopNotifier.notify(t('inbox.conversations.newOneWaiting'))

    components:
      ChevronLeftIcon: ChevronLeftIcon
      Controls: Controls
      ConversationInList: ConversationInList
      Spinner: Spinner
      TableConfigurator: TableConfigurator
