import BotbuilderApi from 'models/botbuilder_api'
import ContextParameter from 'models/context_parameter'
import NLG from 'models/nlg'

import VariableGetter from 'models/mixins/variable_getter'

export default class FollowUp
  Object.assign(@prototype, VariableGetter(resourceTypes: ['ContextParameter', 'InternalParameter']))

  @list: (bot, params = {}) ->
    BotbuilderApi.getFollowUps(params)
      .then (data) ->
        total: data.total
        items: (data.items || []).map (followUpData) -> new FollowUp(bot, followUpData)

  @load: (bot, id) ->
    BotbuilderApi.getFollowUp(id)
      .then (data) => new FollowUp(bot, data)

  constructor: (@bot, data = {}) ->
    data ||= {}
    @enabled = true
    @nlg = new NLG(@)
    @sourceIds = []
    @update(data)

  update: (data = {}) ->
    @id = data.id if data.id?
    @label = data.label if data.label?
    @description = data.description if data.description?
    @enabled = data.enabled if data.enabled?
    @nlg = new NLG(@, data.nlg) if data.nlg?
    @sourceIds = data.sourceIds if data.sourceIds?

  save: ->
    if @id
      BotbuilderApi.updateFollowUp(@)
    else
      BotbuilderApi.createFollowUp(@)
        .then (data) => @update(data)

  delete: ->
    BotbuilderApi.deleteFollowUp(@)

  Object.defineProperties @prototype,
    export:
      get: ->
        id: @id
        label: @label
        description: @description
        enabled: @enabled
        sourceIds: @sourceIds
        nlg: @nlg.export
