
  import { H3, Popover } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { ChevronDownIcon } from '@heroicons/vue/outline'

  export default
    props:
      title: String
      items: Array
      params: Object
      settings:
        type: Object
        default: {}

    data: ->
      location: window.location

    computed:
      submenus: ->
        list = []
        @items.forEach (item) =>
          if item.title?
            list.push(title: item.title, key: item.key, items: [])
          else
            list.push(items: []) if list.length == 0
            list.last.items.push(item)
        list

    methods:
      updateSettings: (submenuKey, open) ->
        return if @settings[submenuKey] == open
        @settings[submenuKey] = open
        @$emit('settings-changed')
        open

    components:
      ChevronDownIcon: ChevronDownIcon
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      H3: H3
      Popover: Popover
