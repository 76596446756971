import BotbuilderApi from 'models/botbuilder_api'
import Project from 'models/project'

import VariableGetter from 'models/mixins/variable_getter'

export default class DialogNode
  Object.assign(@prototype, VariableGetter())

  @TYPES = [
    'BotIntent'
    'DialogAction'
    'TriggerIntent'
    'UserIntent'
  ]

  @typed: (dialogModule, data) ->
    nodeClass = switch data.type
      when 'TriggerIntent'
        require('./trigger_intent.coffee')
      when 'UserIntent'
        require('./user_intent.coffee')
      when 'BotIntent'
        require('./bot_intent.coffee')
      when 'DialogAction'
        require('./dialog_action.coffee')
    new nodeClass.default(dialogModule, data)

  save: ->
    if @key
      BotbuilderApi.updateNode(@).then (result) =>
        @partialUpdate(result.node)
        @setMetaInfo(result.metaInfo)
    else
      BotbuilderApi.createNode(@).then (nodeData) => @update(nodeData)

  delete: ->
    BotbuilderApi.deleteNode(@)
      .then (response) => @dialogModule.updateNodes(response.affectedResources)

  setMetaInfo: (value) ->
    return unless @dialogModule?
    @dialogModule.nodeMeta[@key] = value
    @dialogModule.nodeMeta[@key].externalTargetNodes = (value.externalTargetNodes || []).map (nodeInfo) =>
      DialogNode.typed(@dialogModule.botConfig.module(nodeInfo.moduleKey), nodeInfo.node)
    @dialogModule.nodeMeta[@key].externalIncomingNodes = (value.externalIncomingNodes || []).map (nodeInfo) =>
      DialogNode.typed(@dialogModule.botConfig.module(nodeInfo.moduleKey), nodeInfo.node)

  Object.defineProperties @prototype,
    intentIdentifier:
      get: ->
        "#{@moduleKey}/#{@key}"
    moduleKey:
      get: -> @dialogModule.key
    moduleLabel:
      get: -> @dialogModule?.label
    botId:
      get: -> @dialogModule.botId
    metaInfo:
      get: ->
        @dialogModule?.nodeMeta[@key]
    searchNode: # for VariableGetter
      get: ->
        @
    environments:
      get: ->
        (Project.ENVIRONMENT_KEYS
          .filter (env) => @metaInfo?.environments?.includes(env)
        ) || ['DEV']
    externalIncomingNodes:
      get: ->
        @dialogModule?.nodeMeta[@key]?.externalIncomingNodes || []
