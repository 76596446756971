
  import FollowUp from 'models/follow_up'
  import KnowledgeSource from 'models/knowledge_source'

  import { ActionMenu, Button, EmptyState, H4, Input, KeyListbox, Modal, TileList } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: KnowledgeSource

    data: ->
      source: @modelValue
      followUps: []
      followUpToAddId: null
      newFollowUp: new FollowUp(@$root.currentBot, sourceIds: [@modelValue.id])
      sending: false
      PlusIcon: PlusIcon

    computed:
      assignedFollowUps: ->
        @followUps.filter (followUp) =>
          followUp.sourceIds.includes(@source?.id)
      unassignedFollowUps: ->
        @followUps.filter (followUp) =>
          !followUp.sourceIds.includes(@source?.id)
      addingDisabled: ->
        !@followUpToAddId || @sending
      createDisabled: ->
        !@newFollowUp.label || @sending

    created: ->
      @followUps = (await FollowUp.list(@$root.currentBot)).items

    methods:
      goTo: (followUp) ->
        @$router.push(name: 'followUp', params: {followUpId: followUp.id})
      actionMenuItems: (followUp) ->
        [
          {label: 'Remove from this source', icon: XIcon, method: => @remove(followUp)}
        ]
      add: ->
        @sending = true
        selectedFollowUp = @followUps.find (followUp) => followUp.id == @followUpToAddId
        selectedFollowUp.sourceIds.push(@source.id)
        selectedFollowUp.save()
          .then => @$refs.addingModal.close()
          .finally =>
            @sending = false
            @followUpToAddId = null
      remove: (followUp) ->
        followUp.sourceIds = followUp.sourceIds.filter (sourceId) => sourceId != @source.id
        followUp.save()
      create: ->
        @sending = true
        @newFollowUp.save()
          .then =>
            @$router.push(name: 'followUp', params: {followUpId: @newFollowUp.id})
          .finally => @sending = false
      cancelFollowUpCreation: ->
        @newFollowUp = new FollowUp(@$root.currentBot, sourceIds: [@source.id])

    components:
      ActionMenu: ActionMenu
      Button: Button
      EmptyState: EmptyState
      H4: H4
      Input: Input
      KeyListbox: KeyListbox
      Modal: Modal
      TileList: TileList
