
  import ContentList from './content_list.vue'
  import SchemaTable from './schema_table.vue'
  import Wrapper from './wrapper.vue'
  import { EmptyState } from 'components/generic'

  export default
    methods:
      promptSchemaCreation: ->
        @$router.push(name: 'entitySchemaList', query: {create: true})

    components:
      ContentList: ContentList
      EmptyState: EmptyState
      SchemaTable: SchemaTable
      Wrapper: Wrapper
