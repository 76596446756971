import BotbuilderApi from 'models/botbuilder_api'
import Condition from 'models/condition'
import NLG from 'models/nlg'

import VariableGetter from 'models/mixins/variable_getter'

export default class AiSearch
  Object.assign(@prototype, VariableGetter(resourceTypes: ['ContextParameter', 'InternalParameter']))

  @list: (bot, params = {}) ->
    BotbuilderApi.getAiSearches(params)
      .then (data) ->
        total: data.total
        items: (data.items || []).map (aiSearchData) => new AiSearch(bot, aiSearchData)

  @load: (bot, id) ->
    BotbuilderApi.getAiSearch(id)
      .then (data) => new AiSearch(bot, data)

  constructor: (@bot, data = {}) ->
    data ||= {}
    @enabled = true
    @autoSync = true
    @nlg = new NLG(@)
    @update(data)

  update: (data = {}) ->
    @id = data.id if data.id?
    @label = data.label if data.label?
    @description = data.description if data.description?
    @enabled = data.enabled if data.enabled?
    @autoSync = data.autoSync if data.autoSync?
    @nlg = new NLG(@, data.nlg) if data.nlg?
    @schemaKey = data.schemaKey if data.schemaKey?
    @activeIf = if data.activeIf? then new Condition(data.activeIf) else null
    @updateReadOnlyAttributes(data)

  updateReadOnlyAttributes: (data) ->
    @syncStatus = data.syncStatus # SYNCING | PROCESSING | READY
    @timeCreated = data.timeCreated if data.timeCreated?
    @timeUpdated = data.timeUpdated if data.timeUpdated?
    @timeLastSynchronization = data.timeLastSynchronization if data.timeLastSynchronization?

  reload: ->
    BotbuilderApi.getAiSearch(@id)
      .then (data) => @updateReadOnlyAttributes(data)

  save: ->
    if @id
      BotbuilderApi.updateAiSearch(@)
    else
      BotbuilderApi.createAiSearch(@)
        .then (data) => @update(data)

  delete: ->
    BotbuilderApi.deleteAiSearch(@)

  Object.defineProperties @prototype,
    timeLastSynchronizationFormatted:
      get: ->
        return '' if !@timeLastSynchronization
        moment(@timeLastSynchronization).format(t('formats.dateTime'))
    export:
      get: ->
        id: @id
        label: @label
        description: @description
        enabled: @enabled
        autoSync: @autoSync
        schemaKey: @schemaKey
        nlg: @nlg.export
        activeIf: @activeIf
