
  import { Button, Dropdown } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      sources: Array
      withAddButton:
        type: Boolean
        default: true
      align:
        type: String
        default: 'left'

    data: ->
      followUp: null
      sourceQuery: ''
      selectedSourceIds: []

    computed:
      filteredSources: ->
        @sources
          .filter (source) => source.label.toLowerCase().startsWith(@sourceQuery.toLowerCase())
          .sortByString('label')

    watch:
      selectedSourceIds: ->
        @$emit('select', @selectedSourceIds)

    methods:
      selectSources: ->
        @filteredSources.forEach (source) =>
          @selectedSourceIds.push(source.id) if !@selectedSourceIds.includes(source.id)
      deselectSources: ->
        @selectedSourceIds = @selectedSourceIds.filter (sourceId) =>
          !@filteredSources.map((source) -> source.id).includes(sourceId)
      clear: ->
        @sourceQuery = ''
        @selectedSourceIds = [] if @withAddButton
      apply: ->
        @$emit('add', @selectedSourceIds)
        @$refs.dropdown.close()

    components:
      Button: Button
      Dropdown: Dropdown
      XIcon: XIcon
