
  import AiSearch from 'models/ai_search'
  import FollowUp from 'models/follow_up'
  import Message from 'models/message'
  import MessageVariation from 'models/message_variation'

  import MessageEditor from './message_editor.vue'
  import TemplateDropArea from './template_drop_area.vue'
  import DraggableItem from 'components/shared/draggable_item.vue'
  import Draggable from 'vuedraggable'

  export default
    props:
      modelValue: MessageVariation
      medium: String

    data: ->
      variation: @modelValue
      draggingTemplate: false
      draggedTemplateType: null
      scrolledToBottom: false
      showMessageList: true
      channelTypeLogos: Globals.channelTypeLogos
      messageEditorRefs: []

    computed:
      availableTemplates: ->
        templates = {}
        templateList = if @variation.nlg?.host instanceof AiSearch
          switch @medium
            when 'PHONE'
              ['text']
            when 'WHATSAPP'
              ['whatsappList']
            else
              ['resultPresentation']
        else if @variation.nlg?.host instanceof FollowUp
          switch @medium
            when 'PHONE'
              ['text']
            when 'WHATSAPP'
              Message.TEMPLATES_PER_CHANNEL_TYPE[@medium].filter (template) -> template != 'delay'
            else
              ['gallery']
        else
          Message.TEMPLATES_PER_CHANNEL_TYPE[@medium] || Message.TEMPLATES_PER_CHANNEL_TYPE.default
        templateList.forEach (templateType) ->
          return unless Message.TEMPLATES[templateType]?
          templates[templateType] = Message.TEMPLATES[templateType]
        templates
      onlyOneMessage: ->
        @variation.nlg?.host instanceof AiSearch || @variation.nlg?.host instanceof FollowUp
      noMoreMessages: ->
        @onlyOneMessage && @variation.messages.length >= 1

    watch:
      modelValue: ->
        @variation = @modelValue

    mounted: ->
      @$refs.scrollElement.addEventListener 'scroll', @setScrolledToBottom
      @observer = new MutationObserver (entries) =>
        @scrollToBottom() if @scrolledToBottom
      @observer.observe(@$refs.messageArea.$el, subtree: true, childList: true)

    unmounted: ->
      @observer.disconnect()

    beforeUpdate: ->
      @messageEditorRefs = []

    methods:
      cloneMessage: (index) ->
        list = @variation.messages
        list.splice(index, 0, list[index].clone())
        @update()
      removeMessage: (index) ->
        @showMessageList = false
        @$nextTick =>
          @variation.messages.splice(index, 1)
          @update()
          @showMessageList = true
      setMessageEditorRef: (el, index) ->
        @messageEditorRefs[index] = el if el?
      dropTemplate: (event, index) ->
        @draggingTemplate = false
        transferData = JSON.parse(event.dataTransfer.getData('value'))
        return if !transferData.template?
        @variation.addTemplateMessage(index, transferData.template)
        @update()
        @$nextTick =>
          @messageEditorRefs[index].focus()
          # scroll to new message (only after it has been expanded):
          # @$nextTick =>
          #   $messageElement = $(@$el).find('.message').last()
      update: ->
        @$emit('update:modelValue', @variation)
      setScrolledToBottom: ->
        @scrolledToBottom = @$refs.scrollElement.scrollTop + 10 >= @$refs.scrollElement.scrollHeight - @$refs.scrollElement.offsetHeight
      scrollToBottom: ->
        @$refs.scrollElement.scrollTop = @$refs.scrollElement.scrollHeight

    components:
      Draggable: Draggable
      DraggableItem: DraggableItem
      MessageEditor: MessageEditor
      TemplateDropArea: TemplateDropArea
