
  import BotbuilderApi from 'models/botbuilder_api.coffee'

  import { DocumentationLink, PageHeader, Tabs } from 'components/generic'

  export default
    data: ->
      tabs = [
        {id: 'content', name: 'Entities'}
        {id: 'entitySchemaList', name: 'Schemas'}
        {id: 'aiSearchList', name: 'AI search'}
      ]

      currentTab: if @$route.name.startsWith('entitySchema')
        'entitySchemaList'
      else if @$route.name.startsWith('aiSearch')
        'aiSearchList'
      else
        'content'
      tabs: tabs

    methods:
      pushRoute: ->
        @$router.push(name: @currentTab)

    components:
      DocumentationLink: DocumentationLink
      PageHeader: PageHeader
      Tabs: Tabs
