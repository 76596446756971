import { railsEnv } from 'rails_data'
import AttributeMapping from 'models/attribute_mapping.coffee'
import Bot from 'models/bot.coffee'
import ContentEntity from 'models/content_entity.coffee'
import EntitySchema from 'models/entity_schema.coffee'
import ContextParameter from 'models/context_parameter.coffee'
import DialogTest from 'models/dialog_test.coffee'
import InboxMessage from 'models/inbox_message.coffee'
import Utterance from 'models/utterance.coffee'
import Workflow from 'models/workflow.coffee'
import WorkflowTrace from 'models/workflow_trace.coffee'
import WorkflowVariable from 'models/workflow_variable.coffee'
import Integration from 'models/integration.coffee'
import ConfigHint from 'models/config_hint.coffee'
import ObjectProcessor from 'models/object_processor.coffee'

import { alert } from 'helpers'

BASE_URL = if railsEnv == 'production' then 'https://api.mercury.ai/v2/' else 'https://api.mercury.ai/beta2/'

window.Api = class BotbuilderApi
  @baseCall: (method, endpoint, {params, body, rawBody, headers, token, multipart} = {}) ->
    params ||= {}
    token ||= Globals.project?.token
    new Promise (resolve, reject) =>
      queryString = if Object.keys(params).length
        '?' + Object.keys(params)
          .map((k) -> k + '=' + encodeURIComponent(params[k]))
          .join('&')
      else
        ''
      url = BASE_URL + endpoint + queryString
      fetch(
        url,
        method: method.toUpperCase(),
        body: if rawBody? then rawBody else if body? then JSON.stringify(body) else null,
        headers: if multipart
          Object.assign(
            'X-Requested-With': 'XMLHttpRequest'
            'Accept': 'application/json'
            'Accept-Language': Globals.currentUser?.language || 'en'
            'Authorization': "Bearer #{token}"
          , headers)
        else
          Object.assign(
            'X-Requested-With': 'XMLHttpRequest'
            'Content-Type': 'application/json'
            'Accept': 'application/json'
            'Accept-Language': Globals.currentUser?.language || 'en'
            'Authorization': "Bearer #{token}"
          , headers)
      )
        .then (response) ->
          if response.ok
            resolve(response)
          else if response.status == 401
            reject(
              success: false
              message: "You are not authorized to access the requested resource.\n(#{method.toUpperCase()} #{url})"
              status: response.status
            )
          else
            response.json().then (data) -> reject(Object.assign(data, status: response.status))
        .catch(reject)

  @call: (method, endpoint, {params, body, rawBody, noAlert, multipart} = {}) =>
    new Promise (resolve, reject) =>
      projectId = Globals.project?.uuid
      if !projectId?
        console.error('No project id set.')
        reject()
      @baseCall(method, "projects/#{projectId}/#{endpoint}", params: params, body: body, rawBody: rawBody, multipart: multipart)
        .then (response) -> response.json()
        .then (response) -> resolve(response.data)
        # .catch(reject)
        .catch (response) ->
          await alert(response.message) if response.status != 404 && !noAlert
          reject(response)

  # bot-related

  @convertBot: (oldBotId) =>
    @call('post', "bots/convert/#{oldBotId}")

  # bot CRUD
  @getBots: =>
    @call('get', 'bots')
      .then (data) -> data.map (botData) -> new Bot(botData)
  @createBot: (bot) =>
    @call('post', 'bots', body: bot.export)
  @createBotFromTemplate: (bot, template) =>
    @call('post', 'bots', body: Object.assign({botTemplate: template}, bot.export))
  @updateBot: (bot) =>
    @call('patch', "bots/#{bot.id}", body: bot.export)
  @deleteBot: (bot) =>
    @call('delete', "bots/#{bot.id}")

  # bot config
  @getConfig: (botId, light = true) =>
    @call('get', "bots/#{botId}/config", params: {light: light})
  @updateConfig: (botConfig) =>
    @call('patch', "bots/#{botConfig.id}/config", body: botConfig.export)
  @getConfigMetaInfo: (botId) =>
    @call('get', "bots/#{botId}/metainfo")

  # tools
  @getHints: (bot, params) =>
    @call('get', "bots/#{bot.id}/tools/validate", params: params)
      .then (data) -> (data.items || []).map (hintData) -> new ConfigHint(hintData)
  @autogenerateModule: (bot, options) =>
    @call('post', "bots/#{bot.id}/tools/autoModule", body: options)
  @autogenerateUtterances: (bot, {utteranceCollection, language}) ->
    utteranceList = utteranceCollection.forLanguage(language).map (utterance) -> utterance.export
    @call('post', "bots/#{bot.id}/tools/autoUtterances", body: {utterances: utteranceList, language: language}, noAlert: true)
      .then (data) -> data.map (utteranceData) -> new Utterance(utteranceData)
  @noParseUtterances: (bot, params = {}) ->
    @call('post', "bots/#{bot.id}/tools/noParseUtterances", body: params, noAlert: true)
      .then (data) -> data.map (utteranceData) -> new Utterance(utteranceData)

  # dialog modules
  @getModule: (botId, moduleKey) =>
    @call('get', "bots/#{botId}/modules/#{moduleKey}")
  @getModuleMetaInfo: (botId, moduleKey) =>
    @call('get', "bots/#{botId}/modules/#{moduleKey}/metainfo")
  @createModule: (module) =>
    @call('post', "bots/#{module.botId}/modules", body: module.export)
  @updateModule: (botId, module) =>
    @call('patch', "bots/#{botId}/modules/#{module.key}", body: module.export)
  @updateModuleSettings: (module) =>
    data =
      label: module.label
      description: module.description
      status: module.status
      triggerSettings: module.triggerSettings.export
    @call('patch', "bots/#{module.botId}/modules/#{module.key}/settings", body: data)
  @deleteModule: (module) =>
    @call('delete', "bots/#{module.botId}/modules/#{module.key}")

  # context interfaces
  @getContextInterface: (botId, contextInterfaceKey) =>
    @call('get', "bots/#{botId}/contextInterfaces/#{contextInterfaceKey}")
  @updateContextInterface: (contextInterface) =>
    @call('patch', "bots/#{contextInterface.botId}/contextInterfaces/#{contextInterface.parameterKey}", body: contextInterface.export)

  # hooks
  @getHook: (botId, key) =>
    @call('get', "bots/#{botId}/hooks/#{key}")
  @createHook: (hook) =>
    @call('post', "bots/#{hook.botId}/hooks", body: hook.export)
  @updateHook: (hook) =>
    @call('patch', "bots/#{hook.botId}/hooks/#{hook.key}", body: hook.export)
  @deleteHook: (hook) =>
    @call('delete', "bots/#{hook.botId}/hooks/#{hook.key}")

  # dialog nodes
  @getNode: (botId, module, nodeKey) =>
    @call('get', "bots/#{botId}/modules/#{module.key}/nodes/#{nodeKey}")
  @createNode: (node) =>
    @call('post', "bots/#{node.botId}/modules/#{node.moduleKey}/nodes", body: node.export)
  @updateNode: (node) =>
    @call('patch', "bots/#{node.botId}/modules/#{node.moduleKey}/nodes/#{node.key}", body: node.export)
  @deleteNode: (node) =>
    @call('delete', "bots/#{node.botId}/modules/#{node.moduleKey}/nodes/#{node.key}")
  @mergeNode: (node, nodeToBeMerged) =>
    @call(
      'patch',
      "bots/#{node.botId}/modules/#{node.moduleKey}/nodes/#{node.key}/merge",
      body: {nodeKey: nodeToBeMerged.key, moduleKey: nodeToBeMerged.moduleKey}
    )

  # search
  @search: (botId, query, {moduleKey, node, types, context, includeModuleKey, parameterKey, limit, statuses} = {}) =>
    params = query: (query || '')
    params.moduleKeys = moduleKey if moduleKey?
    params.currentNode = node.intentIdentifier if node?
    params.resourceTypes = types.join(',') if types?
    params.context = context if context?
    params.includeModuleKey = includeModuleKey if includeModuleKey?
    params.parameterKey = parameterKey if parameterKey?
    params.limit = limit if limit?
    params.statuses = statuses if statuses?
    @call('get', "bots/#{botId}/search", params: params)

  @sendMessage: (botId, sessionId, message, language) =>
    messageObject =
      type: 'Bubble'
      attachments: message.attachments
    @call('post', "bots/#{botId}/tryme", body: {message: messageObject, sessionId: sessionId, language: language})
      .then (data) ->
        botMessages = data.botMessages.map (messageData) -> new InboxMessage(messageData)
        botMessages[0].interpretation = data.botBehavior?.targetIntent
        Object.assign({}, data, botMessages: botMessages)

  # progress
  @getProgressCollection: =>
    @call('get', 'bots/progresscollections')

  # context parameters
  @getContextParameters: ({includeDeleted} = {includeDeleted: false}) =>
    @call('get', 'context', params: {includeDeleted: includeDeleted})
      .then (data) -> data.map (cpData) -> new ContextParameter(cpData)
  @createContextParameter: (contextParameter) =>
    @call('post', 'context', body: contextParameter.export)
  @updateContextParameter: (contextParameter) =>
    @call('patch', "context/#{contextParameter.key}", body: contextParameter.export)
  @deleteContextParameter: (contextParameter) =>
    @call('delete', "context/#{contextParameter.key}")

  # workflows
  @getWorkflows: =>
    @call('get', 'workflows')
      .then (data) -> data.map (workflowData) -> new Workflow(workflowData)
  @getWorkflow: (id) =>
    @call('get', "workflows/#{id}")
      .then (workflowData) -> new Workflow(workflowData)
  @createWorkflow: (workflow) =>
    @call('post', 'workflows', body: workflow.export)
  @updateWorkflow: (workflow) =>
    @call('patch', "workflows/#{workflow.id}", body: workflow.export)
  @deleteWorkflow: (workflow) =>
    @call('delete', "workflows/#{workflow.id}")
  @getWorkflowVariables: (step) =>
    @call('get', "workflows/#{step.workflowId}/variables?stepKey=#{step.key}")
      .then (data) -> (data.variables || []).map (variableData) -> new WorkflowVariable(variableData)
  @getWorkflowTraces: (workflow) =>
    @call('get', "workflows/#{workflow.id}/traces")
      .then (data) -> (data || []).map (traceData) -> new WorkflowTrace(workflow, traceData)

  # integrations
  @getIntegrations: =>
    @call('get', 'integrations')
      .then (data) -> data.map (integrationData) -> new Integration(integrationData)
  @getIntegration: (id) =>
    @call('get', "integrations/#{id}")
      .then (integrationData) -> new Integration(integrationData)
  @createIntegration: (integration) =>
    @call('post', 'integrations', body: integration.export)
  @updateIntegration: (integration) =>
    @call('patch', "integrations/#{integration.id}", body: integration.export)
  @deleteIntegration: (integration) =>
    @call('delete', "integrations/#{integration.id}")
  @inspectIntegration: (integration) =>
    @call('get', "integrations/#{integration.id}/inspect")
  @executeIntegration: (integration) =>
    @call('get', "integrations/#{integration.id}/execute")

  # content schema
  @getEntitySchemas: =>
    @call('get', 'bots/content/schema')
      .then (data) -> data.map (schemaData) -> new EntitySchema(schemaData)
  @getEntitySchema: (key) =>
    @call('get', "bots/content/schema/#{key}")
      .then (schemaData) -> new EntitySchema(schemaData)
  @createEntitySchema: (schema) =>
    @call('post', 'bots/content/schema', body: schema.export)
  @updateEntitySchema: (schema) =>
    @call('patch', "bots/content/schema/#{schema.key}", body: schema.export)
  @deleteEntitySchema: (schema) =>
    @call('delete', "bots/content/schema/#{schema.key}")
  @deleteEntitySchemaContent: (schema) =>
    @call('delete', "bots/content/entity/batch/#{schema.key}")
  # content
  @getEntities: (language, params = {}) =>
    filteredParams = Object.fromEntries(
      Object.entries(params)
        .filter ([key, value]) -> value?
        .map ([key, value]) -> [key, value]
    )
    @call('get', "bots/content/entity/#{language}", params: filteredParams)
      .then (data) ->
        Object.assign(
          data,
          items: (data.items || []).map (entityData) -> new ContentEntity(language, entityData)
        )
  @getEntity: (language, key, {noAlert} = {}) =>
    @call('get', "bots/content/entity/#{language}/#{key}", noAlert: noAlert)
      .then (entityData) -> new ContentEntity(language, entityData)
  @createEntity: (language, entity) =>
    @call('post', "bots/content/entity/#{language}", body: entity.export)
  @updateEntity: (language, entity) =>
    @call('patch', "bots/content/entity/#{language}/#{entity.key}", body: entity.export)
  @deleteEntity: (entity) =>
    @call('delete', "bots/content/entity/#{entity.key}")
  @createContentViaJson: (schemaKey, json, {deleteExisting} = {}) =>
    @call(
      'post'
      "bots/content/entity/batch/#{schemaKey}"
      rawBody: json
      params: {deleteExisting: deleteExisting}
    )
  # attribute mappings
  @getAttributeMappings: (parameterKey) =>
    @call('get', 'bots/content/attributeMapping', params: {parameterKey: parameterKey})
      .then (data) -> data.map (attributeMappingData) -> new AttributeMapping(attributeMappingData)
  @createAttributeMapping: (attributeMapping) =>
    @call('post', 'bots/content/attributeMapping', body: attributeMapping.export)
  @updateAttributeMapping: (attributeMapping) =>
    @call('patch', "bots/content/attributeMapping/#{attributeMapping.id}", body: attributeMapping.export)
  @deleteAttributeMapping: (attributeMapping) =>
    @call('delete', "bots/content/attributeMapping/#{attributeMapping.id}")

  # AI search
  @getAiSearches: (params) =>
    @call('get', 'bots/content/aisearches', params: params)
  @getAiSearch: (id) =>
    @call('get', "bots/content/aisearches/#{id}")
  @createAiSearch: (aiSearch) =>
    @call('post', "bots/content/aisearches", body: aiSearch.export)
  @updateAiSearch: (aiSearch) =>
    @call('patch', "bots/content/aisearches/#{aiSearch.id}", body: aiSearch.export)
  @deleteAiSearch: (aiSearch) =>
    @call('delete', "bots/content/aisearches/#{aiSearch.id}")

  # tests
  @getDialogTests: =>
    @call('get', 'bots/botDialogTests')
      .then (data) -> data.map (testData) -> new DialogTest(testData)
  @getDialogTest: (id) =>
    @call('get', "bots/botDialogTests/#{id}")
      .then (data) -> new DialogTest(data)
  @createDialogTest: (test) ->
    @call('post', 'bots/botDialogTests', body: test.export)
  @updateDialogTest: (test, {noAlert} = {}) ->
    @call('patch', "bots/botDialogTests/#{test.id}", body: test.export, noAlert: noAlert)
  @deleteDialogTest: (test) ->
    @call('delete', "bots/botDialogTests/#{test.id}")
  @executeDialogTest: (test) ->
    @call('get', "bots/botDialogTests/#{test.id}/execute")

  # knowledge
  @getKnowledgeSettings: =>
    @call('get', 'knowledge/settings')
  @updateKnowledgeSettings: (settings) =>
    @call('patch', 'knowledge/settings', body: settings)
  @getKnowledgeSources: (params) =>
    @call('get', 'knowledge/sources', params: params)
  @getKnowledgeSourceStatus: (id, {noAlert} = {}) =>
    @call('get', "knowledge/sources/#{id}", noAlert: noAlert)
  @getKnowledgeSourceConfig: (id, {noAlert} = {}) =>
    @call('get', "knowledge/sources/#{id}/config", noAlert: noAlert)
  @getKnowledgeSourceDocumentUrl: (doc) =>
    @call('get', "knowledge/sources/#{doc.source.id}/documents/#{doc.id}")
  @updateKnowledgeSourceDocument: (doc, file) =>
    formData = new FormData()
    formData.append('file', file)
    @call('patch', "knowledge/sources/#{doc.source.id}/documents/#{doc.id}", rawBody: formData, multipart: true)
  @getKnowledgeSourceDocuments: (source, params = {}) =>
    Object.assign(params, sourceId: source.id)
    @call('get', 'knowledge/documents', params: params)
  @getKnowledgeSourceLabel: (id) =>
    @call('get', "knowledge/sources/#{id}/label", noAlert: true)
  @createKnowledgeSource: (source) =>
    @call('post', 'knowledge/sources', body: source.export)
  @updateKnowledgeSource: (source) =>
    @call('patch', "knowledge/sources/#{source.id}/config", body: source.export)
  @recrawlKnowledgeSource: (source) =>
    @call('post', "knowledge/sources/#{source.id}/recrawl")
  @uploadKnowledgeSourceDocuments: (source, files) =>
    formData = new FormData()
    files.forEach (file) -> formData.append('file', file)
    @call('post', "knowledge/sources/#{source.id}/upload", rawBody: formData, multipart: true)
  @deleteKnowledgeSourceDocument: (doc) =>
    @call('delete', "knowledge/sources/#{doc.source.id}/documents/#{doc.id}")
  @deleteKnowledgeSource: (source) =>
    @call('delete', "knowledge/sources/#{source.id}")
  @knowledgeTryme: (params) =>
    @call('post', 'knowledge/tryme', body: params)

  # follow-ups
  @getFollowUps: (params) =>
    @call('get', 'knowledge/followups', params: params)
  @getFollowUp: (id) =>
    @call('get', "knowledge/followups/#{id}")
  @createFollowUp: (followUp) =>
    @call('post', "knowledge/followups", body: followUp.export)
  @updateFollowUp: (followUp) =>
    @call('patch', "knowledge/followups/#{followUp.id}", body: followUp.export)
  @deleteFollowUp: (followUp) =>
    @call('delete', "knowledge/followups/#{followUp.id}")

  # versions
  @getMacroChanges: (params = {}) =>
    @call('get', 'changes', params: params)
  @getMacroChange: (id) =>
    @call('get', "changes/#{id}")
  @restoreMacroChange: (change) =>
    # this restores the version _before_ the given change
    @call('post', "changes/#{change.id}/restore")

export default Api
