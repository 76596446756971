
  import FollowUp from 'models/follow_up'
  import KnowledgeSource from 'models/knowledge_source'

  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import NLGEditor from 'components/botbuilder/nlg/nlg_editor.vue'
  import SourceSelector from './source_selector.vue'
  import Wrapper from './wrapper.vue'
  import { BackLink, Button, Dropdown, H3, H4, Input, Label, RadioPanels } from 'components/generic'
  import { PencilIcon, XIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      followUp: null
      knowledgeSources: []
      sourceQuery: ''
      debounceTimeout: null
      editing: false
      PencilIcon: PencilIcon
      enabled: 'on'
      enabledOptions: [
        {key: 'on', label: 'yes', description: 'This follow-up will be played for the selected knowledge sources.'}
        {key: 'off', label: 'no', description: 'Don’t use this follow-up.'}
      ]

    computed:
      sources: ->
        @knowledgeSources
          .filter (source) => @followUp.sourceIds.includes(source.id)
          .sortByString('label')
      availableSources: ->
        @knowledgeSources
          .filter (source) => !@followUp.sourceIds.includes(source.id)
          .sortByString('label')

    created: ->
      window.breadcrumbs.strip()
        .append(text: 'Follow-ups', callback: => @$router.push(name: 'followUpList'))
      @loadFollowUp()
      @knowledgeSources = (await KnowledgeSource.list(pageSize: 999)).items

    methods:
      loadFollowUp: ->
        @followUp = await FollowUp.load(@$root.currentBot, @$route.params.followUpId)
        @enabled = if @followUp.enabled then 'on' else 'off'
        window.breadcrumbs.append(text: @followUp.label)
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @followUp.save()), 1000)
      addSources: (ids) ->
        ids.forEach (id) => @followUp.sourceIds.push(id)
        @update()
      removeSource: (source) ->
        @followUp.sourceIds = @followUp.sourceIds.filter (id) => id != source.id
        @update()
      updateEnabled: ->
        @followUp.enabled = @enabled == 'on'
        @update()
      goToSource: (source) ->
        @$router.push(name: 'knowledgeSource', params: {sourceId: source.id})

    components:
      BackLink: BackLink
      Button: Button
      Dropdown: Dropdown
      H3: H3
      H4: H4
      Input: Input
      Label: Label
      LanguageSelector: LanguageSelector
      NLGEditor: NLGEditor
      RadioPanels: RadioPanels
      SourceSelector: SourceSelector
      Wrapper: Wrapper
      XIcon: XIcon
