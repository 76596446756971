
  import KnowledgeSource from 'models/knowledge_source'
  import { confirm } from 'helpers'

  import DocumentAdder from './document_adder.vue'
  import DocumentRow from './document_row.vue'
  import { Api2PaginationWithNumbers, Button, Input, Label, Spinner, Textarea } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/outline'

  export default

    props:
      modelValue: KnowledgeSource

    data: ->
      source: @modelValue
      pagination: null
      searchText: ''
      textFileName: ''
      textFileContent: ''
      uploading: false
      PlusIcon: PlusIcon

    computed:
      displaySpinner: ->
        @pagination && @pagination.items.length == 0 &&
          (@pagination.loading || @source.inProgress)
      spinnerMessage: ->
        if @pagination?.loading
          'Loading documents ...'
        else if @source?.inProgress
          'Waiting for documents ...'

    methods:
      uploadFiles: (files) ->
        @uploading = true
        @source.upload(files)
          .then =>
            @searchText = ''
            @$emit('reload')
          .finally =>
            @uploading = false
      deleteDocument: (doc, index) ->
        return unless await confirm("Do you really want do delete “#{doc.filename}” from this knowledge source?")
        doc.delete()
          .then => @pagination.remove(index)
      uploadText: ->
        files = [new File([@textFileContent], @textFileName)]
        @uploadFiles(files)
          .then =>
            @textFileName = ''
            @textFileContent = ''
      update: ->
        @$emit('update:modelValue', @source)
      setPaginationRef: (el) ->
        @pagination = el

    components:
      Api2PaginationWithNumbers: Api2PaginationWithNumbers
      Button: Button
      DocumentAdder: DocumentAdder
      DocumentRow: DocumentRow
      Input: Input
      Label: Label
      Spinner: Spinner
      Textarea: Textarea
