import BotbuilderApi from 'models/botbuilder_api'
import ContextParameter from 'models/context_parameter'
import EdgeVariable from 'models/edge_variable'
import InternalParameter from 'models/internal_parameter'
import SlotRole from 'models/slot_role'

export default ({resourceTypes} = {}) ->
  resourceTypes ||= ['ContextParameter', 'EdgeVariable', 'InternalParameter', 'SlotRole']

  searchVariables: (query, {types, limit} = {}) ->
    types = types.filter (type) -> resourceTypes.includes(type)
    BotbuilderApi.search(
      @botId || @bot.id
      query
      types: types
      limit: limit
      context: @searchContext
      node: @searchNode
    )
      .then (data) =>
        data
          .sort (a, b) -> b.score - a.score
          .map (matchData) =>
            switch matchData.resourceType
              when 'ContextParameter'
                new ContextParameter(matchData.entity)
              when 'EdgeVariable'
                new EdgeVariable(matchData.entity)
              when 'InternalParameter'
                new InternalParameter(matchData.entity)
              when 'SlotRole'
                new SlotRole(matchData.entity)

  loadVariables: ->
    @_variablesLoading = true
    @searchVariables(
      ''
      types: resourceTypes.filter (type) -> type != 'ContextParameter'
      limit: 999
    )
      .then (@_variables) => @_variables
      .finally => @_variablesLoading = false

  getVariables: ->
    if @_variablesLoading
      @_loadingVariablesPromise
    else
      @_loadingVariablesPromise = @loadVariables()
