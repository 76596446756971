
  import AiSearch from 'models/ai_search'
  import DialogNode from 'models/dialog_node'
  import FollowUp from 'models/follow_up'
  import ContextParameter from 'models/context_parameter'
  import Verbalization from 'models/verbalization'

  import VariableSelector from 'components/botbuilder/inputs/variable_selector.vue'
  import { Badge, Button } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Object
      node: [AiSearch, DialogNode, FollowUp, Verbalization]
      parentElement: Node
      possibleVariables: Array

    emits: ['update:modelValue', 'remove', 'exit']

    data: ->
      expression: @modelValue
      variable: null
      component: null
      editing: false
      popoverVerticalPosition: 'top'
      displayRaw: false
      XIcon: XIcon

    computed:
      label: ->
        if @component?
          @component.fullLabel
        else if @variable?
          if componentKey = @expression.text.split('#')[1]
            @variable.label + ' ' + componentKey
          else
            @variable.label
        else
          @expression.text
      raw: ->
        "${#{@expression.text}}"

    watch:
      modelValue: ->
        @expression = @modelValue
      editing: ->
        if @editing
          @$nextTick =>
            @positionPopover()
          setTimeout =>
            document.addEventListener('click', @clickedOutside)
          , 0
        else
          if !@expression.text
            @$emit('remove')
          document.removeEventListener('click', @clickedOutside)
          @$emit('exit')

    created: ->
      @variable = @possibleVariables.find (variable) => variable.reference == @expression.text.split('#')[0]
      componentKey = @expression.text.split('#')[1]
      @component = (@variable?.components || []).find (component) -> component.key == componentKey

    methods:
      edit: ->
        @editing = true
        @$nextTick =>
          @$refs.variableSelection?.focus()
      clickedOutside: (e) ->
        return if e.composedPath().includes(@$refs.popover)
        @editing = false
      update: ->
        @$emit('update:modelValue', @expression)
      positionPopover: ->
        return if !@parentElement?
        # set max width
        @$refs.popover.style.maxWidth = "#{@parentElement.getBoundingClientRect().width - 16}px"
        # set horizontal position
        @$refs.popover.style.left = Math.max(
          @$el.getBoundingClientRect().width / 2 - @$refs.popover.getBoundingClientRect().width / 2,
          @parentElement.getBoundingClientRect().left - @$el.getBoundingClientRect().left + 8,
        ) + 'px'
        setTimeout =>
          if @$refs.popover.getBoundingClientRect().right > @parentElement.getBoundingClientRect().right
            @$refs.popover.style.left = parseFloat(@$refs.popover.style.left) - (@$refs.popover.getBoundingClientRect().right - @parentElement.getBoundingClientRect().right) - 8 + 'px'
        , 0
        # set vertical position
        offsetTop = @$el.getBoundingClientRect().top -
          $(@$el).closest('.message-list-container')[0].getBoundingClientRect().top
        if offsetTop < 130
          @popoverVerticalPosition = 'bottom'
        else
          @popoverVerticalPosition = 'top'

    components:
      Badge: Badge
      Button: Button
      VariableSelector: VariableSelector
      XIcon: XIcon
