
  import BotbuilderApi from 'models/botbuilder_api'
  import Condition from 'models/condition'
  import KnowledgeSource from 'models/knowledge_source'

  import StatusIcon from './status_icon.vue'
  import SourceDocuments from './source_documents.vue'
  import SourceFollowUps from './source_follow_ups.vue'
  import SourceSettings from './source_settings.vue'
  import { BackLink, Button, DocumentationLink, Input, PageHeader, Tabs } from 'components/generic'
  import { PencilIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      source: null
      tabs: ''
      currentTab: if @$route.hash then @$route.hash[1..] else 'settings'
      reload: false
      pollingTimeout: null
      debounceTimeout: null
      editing: false
      PencilIcon: PencilIcon
      tabs: [
        { id: 'settings', name: 'Settings' }
        { id: 'documents', name: 'Documents' }
        { id: 'followUps', name: 'Follow-ups' }
      ]

    computed:
      followUps: ->
        @$root.currentBot.config.followUps.filter (followUp) =>
          followUp.sourceIds.includes(@source?.id)

    watch:
      '$route.hash': ->
        @currentTab = if @$route.hash then @$route.hash[1..] else 'settings'

    created: ->
      @source = await KnowledgeSource.load(@$route.params.sourceId)
      window.breadcrumbs.strip()
        .append(text: 'Knowledge', callback: => @$router.push(name: 'knowledge'))
        .append(text: @source.label)
      @updateAndPoll()

    unmounted: ->
      clearTimeout(@pollingTimeout)

    methods:
      loadDocuments: ->
        if @$refs.documents?.pagination
          if @reload
            @reload = false
            @$refs.documents.pagination.load(page: 0)
          else
            @$refs.documents.pagination.load()
        else
          setTimeout(@loadDocuments, 100)
      reloadDocuments: ->
        @reload = true
        @loadDocuments()
        @poll()
      updateAndPoll: (data) ->
        @source.updateStatusModel(data)
        @loadDocuments()
        if @source.inProgress
          @pollingTimeout = setTimeout(@poll, 3000)
      poll: ->
        return if !@source.id
        BotbuilderApi.getKnowledgeSourceStatus(@source.id)
          .then (data) => @updateAndPoll(data)
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @source.save()), 1000)
      setHash: ->
        @$router.push(hash: "##{@currentTab}")

    components:
      BackLink: BackLink
      Button: Button
      DocumentationLink: DocumentationLink
      Input: Input
      PageHeader: PageHeader
      StatusIcon: StatusIcon
      SourceDocuments: SourceDocuments
      SourceFollowUps: SourceFollowUps
      SourceSettings: SourceSettings
      Tabs: Tabs
