
  import AiSearch from 'models/ai_search'
  import { confirm } from 'helpers'

  import Wrapper from './wrapper.vue'
  import { ActionMenu, Alert, Button, EmptyState, H3, Input, KeyListbox, Modal, Spinner, TileList } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    data: ->
      aiSearches: []
      newAiSearch: new AiSearch(@$root.currentBot)
      loading: false
      creating: false
      PlusIcon: PlusIcon

    computed:
      createDisabled: ->
        @creating || !(@newAiSearch.schemaKey && @newAiSearch.label)
      availableSchemas: ->
        @$root.entitySchemas.filter (schema) =>
          !@aiSearches.map((aiSearch) -> aiSearch.schemaKey).includes(schema.key)
      schemaOptions: ->
        [{label: 'Select schema*'}].concat(@availableSchemas)

    created: ->
      window.breadcrumbs.strip().append(text: 'AI search')
      @loadAiSearches()

    methods:
      loadAiSearches: ->
        @loading = true
        AiSearch.list(@$root.currentBot)
          .then (response) => @aiSearches = response.items
          .finally => @loading = false
      goTo: (aiSearch) ->
        @$router.push(name: 'aiSearch', params: {aiSearchId: aiSearch.id})
      schema: (aiSearch) ->
        @$root.entitySchemas.find (schema) -> schema.key == aiSearch.schemaKey
      cancel: ->
        @newAiSearch = new AiSearch(@$root.currentBot)
      create: ->
        @creating = true
        @newAiSearch.save()
          .then =>
            @$router.push(name: 'aiSearch', params: {aiSearchId: @newAiSearch.id})
          .finally => @creating = false
      actionMenuItems: (search, index) ->
        [
          {label: 'Delete', icon: XIcon, method: => @deleteAiSearch(search, index)}
        ]
      deleteAiSearch: (search, index) ->
        return unless await confirm("Do you really want do delete the AI search “#{search.label}”?")
        search.delete()
          .then => @aiSearches.splice(index, 1)

    components:
      ActionMenu: ActionMenu
      Alert: Alert
      Button: Button
      EmptyState: EmptyState
      H3: H3
      Input: Input
      KeyListbox: KeyListbox
      Modal: Modal
      Spinner: Spinner
      TileList: TileList
      Wrapper: Wrapper
