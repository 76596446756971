
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

  export default
    props:
      buttonClass:
        type: String
        default: ''
      hover:
        type: Boolean
        default: false
      width:
        type: String
        default: 'xs'

    computed:
      widthClasses: ->
        switch @width
          when 'xs' then 'w-screen max-w-xs'
          when 'fit' then ''
          else @width

    methods:
      show: (open) ->
        return if !@hover
        return if open
        @$refs.popoverButton.$el.click()
      hide: (open) ->
        return if !@hover
        return if !open
        @$refs.popoverButton.$el.click()

    components:
      Popover: Popover
      PopoverButton: PopoverButton
      PopoverPanel: PopoverPanel
