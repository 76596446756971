import BotbuilderApi from 'models/botbuilder_api'
import Condition from 'models/condition'
import KnowledgeSourceBaseUrl from 'models/knowledge_source_base_url'
import KnowledgeSourceDocument from 'models/knowledge_source_document'
import Project from 'models/project'

export default class KnowledgeSource
  @list: (params = {}) ->
    BotbuilderApi.getKnowledgeSources(params)
      .then (data) ->
        total: data.total
        items: (data.items || []).map (sourceData) -> new KnowledgeSource(sourceData)

  @load: (id, {noAlert} = {}) ->
    Promise.all([
      BotbuilderApi.getKnowledgeSourceStatus(id, noAlert: noAlert)
      BotbuilderApi.getKnowledgeSourceConfig(id, noAlert: noAlert)
    ])
      .then ([statusData, config]) => new KnowledgeSource(Object.assign({}, config, statusData))

  @label: (id) ->
    BotbuilderApi.getKnowledgeSourceLabel(id)

  constructor: (data) ->
    data ||= {}
    # set default values
    @type = 'http_crawler' # or 'documents'
    @label = ''
    @canonical = ''
    @enabled = true
    # specific for type http_crawler:
    @baseUrls = [new KnowledgeSourceBaseUrl(@)]
    @maxDocuments = 1000
    @mblimit = 10
    @autoUpdate = true
    # end type-specific attributes
    @languages = []
    @environments = Project.ENVIRONMENT_KEYS
    # set given values
    @updateConfig(data)
    @updateStatusModel(data)

  updateConfig: (data) ->
    return if !data?
    @id = data.id if data.id?
    @type = data.type if data.type?
    @label = data.label if data.label?
    @canonical = data.canonical if data.canonical?
    @description = data.description if data.description?
    @enabled = data.enabled if data.enabled?
    @baseUrls = data.baseUrls.map((data) -> new KnowledgeSourceBaseUrl(@, data)) if data.baseUrls?
    @maxDocuments = data.maxDocuments if data.maxDocuments?
    @mblimit = data.mblimit if data.mblimit?
    @activeIf = if data.activeIf? then new Condition(data.activeIf) else null
    @autoUpdate = data.autoUpdate if data.autoUpdate?
    @schemaKey = data.schemaKey if data.schemaKey?
    @languages = data.languages if data.languages?
    @environments = data.environments if data.environments?

  updateStatusModel: (data) ->
    return if !data?
    # common attributes
    @id = data.id if data.id?
    @type = data.type if data.type?
    @label = data.label if data.label?
    @canonical = data.canonical if data.canonical?
    @description = data.description if data.description?
    @enabled = data.enabled if data.enabled?
    # status model only
    @origin = data.origin
    @status = data.status
    @syncStatus = data.syncStatus # SYNCING | PROCESSING | READY
    @totalDocuments = data.totalDocuments
    @totalDocumentSize = data.totalDocumentSize
    @timeCreated = data.timeCreated
    @timeLastSync = data.timeLastSync
    @timeLastEffectiveDatapoint = data.timeLastEffectiveDatapoint

  save: ->
    if @id?
      BotbuilderApi.updateKnowledgeSource(@)
    else
      BotbuilderApi.createKnowledgeSource(@)
        .then (data) => @updateStatusModel(data)

  delete: ->
    BotbuilderApi.deleteKnowledgeSource(@)

  recrawl: ->
    BotbuilderApi.recrawlKnowledgeSource(@)

  upload: (files) ->
    return Promise.resolve() if files.length == 0
    BotbuilderApi.uploadKnowledgeSourceDocuments(@, files)

  getDocuments: (params = {}) =>
    BotbuilderApi.getKnowledgeSourceDocuments(@, params)
      .then (data) =>
        total: data.total
        items: data.items.map (docData) => new KnowledgeSourceDocument(@, docData)

  Object.defineProperties @prototype,
    inProgress:
      get: ->
        ['PROCESSING', 'SYNCING'].includes(@syncStatus)
    timeLastSyncFormatted:
      get: ->
        return '' if !@timeLastSync
        moment(@timeLastSync).format(t('formats.dateTime'))
    timeLastEffectiveDatapointFormatted:
      get: ->
        return '' if !@timeLastEffectiveDatapoint
        moment(@timeLastEffectiveDatapoint).format(t('formats.dateTime'))
    totalDocumentSizeFormatted:
      get: ->
        return "#{(@totalDocumentSize / 1000).toFixed(1)} KB" if @totalDocumentSize < 1000000
        "#{(@totalDocumentSize / 1000000).toFixed(1)} MB"
    maxDocuments:
      get: -> @_maxDocuments
      set: (value) ->
        @_maxDocuments = Math.min(Math.max(value, 1), 1000)
    mblimit:
      get: -> @_mblimit
      set: (value) ->
        @_mblimit = Math.min(Math.max(value, 1), 10)
    export:
      get: ->
        obj =
          id: @id,
          type: @type
          label: @label
          canonical: @canonical
          description: @description
          enabled: @enabled
          activeIf: if @activeIf? then @activeIf.export else null
          languages: @languages
          environments: @environments
        switch @type
          when 'http_crawler'
            obj.baseUrls = @baseUrls.map (settings) -> settings.export
            obj.autoUpdate = @autoUpdate
            obj.maxDocuments = @maxDocuments
            obj.mblimit = @mblimit
        obj
