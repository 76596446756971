
  import { DocumentationLink, PageHeader, Tabs } from 'components/generic'

  export default
    data: ->
      tabs = [
        {id: 'knowledge', name: 'Sources'}
        {id: 'followUpList', name: 'Follow-ups'}
        {id: 'knowledgeSettings', name: 'Settings'}
      ]

      currentTab: if @$route.name.startsWith('followUp')
        'followUpList'
      else if @$route.name == 'knowledgeSettings'
        'knowledgeSettings'
      else
        'knowledge'
      tabs: tabs

    methods:
      pushRoute: ->
        @$router.push(name: @currentTab)

    components:
      DocumentationLink: DocumentationLink
      PageHeader: PageHeader
      Tabs: Tabs
