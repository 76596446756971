TRANSLATIONS =
  en:
    formats:
      date: 'YYYY-MM-DD'
      time: 'HH:mm'
      duration: 'HH:mm:ss'
      dateTime: 'YYYY-MM-DD HH:mm'
    general:
      ok: 'Ok'
      cancel: 'Cancel'
      backToList: 'Back to list'
      desktopNotificationPreferences: 'Please set your desktop notification preferences!'
    inbox:
      reconnecting: 'Inbox is trying to reconnect...'
      tabs:
        chat: 'Live chat'
        chatDescription: 'All conversations that are currently in handover mode.'
        overview: 'Inbox overview'
        overviewDescription: 'All conversations that have been in handover mode.'
        logs: 'Conversation logs'
        logsDescription: 'All conversations. Not updated in realtime.'
      assignment:
        assignedTo: 'Assigned to'
        chat:
          all: 'all live chats'
          mine: 'my live chats'
          myTeams: 'my teams’ live chats'
        overview:
          all: 'all conversations'
          mine: 'my conversations'
          myTeams: 'my teams’ conversations'
        helpTexts:
          assignedToMe: 'assigned to me'
          assignedToOneOfMyTeams: 'assigned to a team colleague'
          assignedToSomeoneElse: 'assigned to some other team'
      filter:
        searchAnswer: 'Search message'
        selectTag: 'Select tag'
      date:
        before: 'Before'
        thisWeek: 'This week'
        yesterday: 'Yesterday'
        today: 'Today'
      conversations:
        avatarAndChannelIcon: 'Avatar & channel icon'
        assign: 'Assign'
        selectAgent: 'Select agent'
        defaultAgentName: 'Any Agent'
        deleteUser: 'Delete user'
        exportUserData: 'Export user data'
        newOneWaiting: 'There’s a bot user waiting for an answer!'
        loading: 'Loading conversations'
        noneInList: 'Nothing needs your attention here'
        noneSelected: 'No conversation selected'
        notes: 'Notes'
        addNote: 'add a note'
        answerTemplates: 'Answer templates'
        selectAnswerTemplate: 'Select answer template'
        uploadFile: 'Upload file'
        reEngageChatbot: 'End session'
        takeOver: 'Take over conversation'
        firstSeen: 'First seen'
        lastSeen: 'Last seen'
        lastMessage: 'Last message'
        lastMessageTime: 'Last message time'
        totalMessages: 'Total messages'
        waitTime: 'Waiting time'
        statusTexts:
          online: 'the user is currently online'
          idle: 'the user is currently idle'
        user:
          firstName: 'First Name'
          lastName: 'Last name'
          email: 'Email'
          phone: 'Phone'
  de:
    formats:
      date: 'DD.MM.YYYY'
      time: 'HH:mm'
      duration: 'HH:mm:ss'
      dateTime: 'DD.MM.YYYY HH:mm'
    general:
      ok: 'Ok'
      cancel: 'Abbrechen'
      backToList: 'Zur Liste'
      desktopNotificationPreferences: 'Einstellung für Desktop-Benachrichtigungen vornehmen'
    inbox:
      reconnecting: 'Versuche neu zu verbinden...'
      tabs:
        chat: 'Livechat'
        chatDescription: 'Alle Unterhaltungen, die aktuell im Livechat-Modus sind.'
        overview: 'Inboxübersicht'
        overviewDescription: 'Alle Unterhaltungen, die im Livechat-Modus sind oder waren.'
        logs: 'Alle Unterhaltungen'
        logsDescription: 'All Unterhaltungen. Kein Live-Update.'
      assignment:
        assignedTo: 'Zugewiesen'
        chat:
          all: 'alle Livechats'
          mine: 'meine Livechats'
          myTeams: 'Livechats meiner Teams'
        overview:
          all: 'alle Konversationen'
          mine: 'meine Konversationen'
          myTeams: 'Konversationen meiner Teams'
        helpTexts:
          assignedToMe: 'mir zugewiesen'
          assignedToOneOfMyTeams: 'einem Teamkollegen zugewiesen'
          assignedToSomeoneElse: 'einem anderen Team zugewiesen'
      filter:
        searchAnswer: 'Nachricht durchsuchen'
        selectTag: 'Nach Kategorie filtern'
      date:
        before: 'Vorher'
        thisWeek: 'Diese Woche'
        yesterday: 'Gestern'
        today: 'Heute'
      conversations:
        avatarAndChannelIcon: 'Avatar & Kanal-Icon'
        assign: 'Zuweisen'
        selectAgent: 'Agent auswählen'
        defaultAgentName: 'Automatisch'
        deleteUser: 'Nutzer löschen'
        exportUserData: 'Nutzerdaten exportieren'
        newOneWaiting: 'Ein Bot-Nutzer wartet auf eine Antwort!'
        loading: 'Lade Konversationen'
        noneInList: 'Keine Konversation vorhanden'
        noneSelected: 'Keine Konversation ausgewählt'
        notes: 'Notizen'
        addNote: 'Notiz hinzufügen'
        answerTemplates: 'Antwortvorlagen'
        selectAnswerTemplate: 'Antwortvorlage auswählen'
        uploadFile: 'Datei hochladen'
        reEngageChatbot: 'Session beenden'
        takeOver: 'Gespräch übernehmen'
        firstSeen: 'Zuerst gesehen'
        lastSeen: 'Zuletzt gesehen'
        lastMessage: 'Letzte Nachricht'
        lastMessageTime: 'Zeit der letzten Nachricht'
        totalMessages: 'Nachrichten insgesamt'
        waitTime: 'Wartezeit'
        statusTexts:
          online: 'der Nutzer ist online'
          idle: 'der Nutzer ist inaktiv'
        user:
          firstName: 'Vorname'
          lastName: 'Nachname'
          email: 'E-Mail'
          phone: 'Telefon'

window.t = (key, language) ->
  language ||= (Globals.currentUser.language || 'en')
  path = [ language[0..2] ].concat(key.split('.'))
  translation = (path) ->
    text = TRANSLATIONS
    for segment in path
      text = text?[segment]
    text
  text = translation(path)
  if !(typeof text == 'string')
    text = translation(['en'].concat(path[1..-1]))
  text

window.l = (time, format) ->
  momentJSObject = if format == 'duration'
    moment.utc(time)
  else
    moment(time)
  momentJSObject.format(
    t('formats.' + format)
  )

# make functions available to all Vue templates
Vue.mixin
  data: ->
    t: t
