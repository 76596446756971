
  import FollowUp from 'models/follow_up'
  import KnowledgeSource from 'models/knowledge_source'
  import { confirm } from 'helpers'

  import SourceSelector from './source_selector.vue'
  import Wrapper from './wrapper.vue'
  import { ActionMenu, Button, EmptyState, H3, Input, KeyListbox, Modal, Spinner, TileList } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    data: ->
      followUps: []
      newFollowUp: new FollowUp(@$root.currentBot)
      knowledgeSources: []
      loading: false
      creating: false
      PlusIcon: PlusIcon

    computed:
      createDisabled: ->
        @creating || !@newFollowUp.label

    created: ->
      window.breadcrumbs.strip().append(text: 'Follow-ups')
      @loadFollowUps()
      @knowledgeSources = (await KnowledgeSource.list(pageSize: 999)).items

    methods:
      loadFollowUps: ->
        @loading = true
        FollowUp.list(@$root.currentBot)
          .then (response) => @followUps = response.items
          .finally => @loading = false
      goTo: (followUp) ->
        @$router.push(name: 'followUp', params: {followUpId: followUp.id})
      cancel: ->
        @newFollowUp = new FollowUp(@$root.currentBot)
      create: ->
        @creating = true
        @newFollowUp.save()
          .then =>
            @$router.push(name: 'followUp', params: {followUpId: @newFollowUp.id})
          .finally => @creating = false
      actionMenuItems: (search, index) ->
        [
          {label: 'Delete', icon: XIcon, method: => @deleteFollowUp(search, index)}
        ]
      deleteFollowUp: (followUp, index) ->
        return unless await confirm("Do you really want do delete the follow-up “#{followUp.label}”?")
        followUp.delete()
          .then => @followUps.splice(index, 1)
      sourceLabels: (followUp) ->
        followUp.sourceIds
          .map (sourceId) =>
            @knowledgeSources.find((source) -> source.id == sourceId)?.label || sourceId
          .join(', ')

    components:
      ActionMenu: ActionMenu
      Button: Button
      EmptyState: EmptyState
      H3: H3
      Input: Input
      KeyListbox: KeyListbox
      Modal: Modal
      Spinner: Spinner
      SourceSelector: SourceSelector
      TileList: TileList
      Wrapper: Wrapper
