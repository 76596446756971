
  import AiSearch from 'models/ai_search'
  import Condition from 'models/condition'

  import ConditionEditor from 'components/botbuilder/condition_editor.vue'
  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import NLGEditor from 'components/botbuilder/nlg/nlg_editor.vue'
  import StatusIcon from 'components/botbuilder/knowledge/status_icon.vue'
  import Wrapper from './wrapper.vue'
  import { BackLink, Button, H3, H4, Input, Label, RadioPanels } from 'components/generic'
  import { PencilIcon, PlusIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      aiSearch: null
      debounceTimeout: null
      editing: false
      pollingTimeout: null
      PencilIcon: PencilIcon
      PlusIcon: PlusIcon
      enabled: 'on'
      enabledOptions: [
        {key: 'on', label: 'yes', description: 'AI search is active for this content schema.'}
        {key: 'off', label: 'no', description: 'Don’t use AI search for this content schema.'}
      ]

    computed:
      schema: ->
        @$root.entitySchemas.find (schema) => schema.key == @aiSearch?.schemaKey

    created: ->
      @loadAiSearch()
      window.breadcrumbs.strip()
        .append(text: 'AI search', callback: => @$router.push(name: 'aiSearchList'))

    unmounted: ->
      clearTimeout(@pollingTimeout)

    methods:
      loadAiSearch: ->
        @aiSearch = await AiSearch.load(@$root.currentBot, @$route.params.aiSearchId)
        @enabled = if @aiSearch.enabled then 'on' else 'off'
        window.breadcrumbs.append(text: @aiSearch.label)
        @poll()
      poll: ->
        return if @aiSearch.syncStatus == 'READY'
        @aiSearch.reload()
          .then =>
            @pollingTimeout = setTimeout(@poll, 3000)
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @aiSearch.save()), 1000)
      updateEnabled: ->
        @aiSearch.enabled = @enabled == 'on'
        @update()
      addCondition: ->
        @aiSearch.activeIf = new Condition()
      removeCondition: ->
        @aiSearch.activeIf = null
        @update()

    components:
      BackLink: BackLink
      Button: Button
      ConditionEditor: ConditionEditor
      H3: H3
      H4: H4
      Input: Input
      Label: Label
      LanguageSelector: LanguageSelector
      NLGEditor: NLGEditor
      RadioPanels: RadioPanels
      StatusIcon: StatusIcon
      Wrapper: Wrapper
