
  import NLG from 'models/nlg.coffee'
  import MessageVariationEditor from './message_variation_editor.vue'
  import { Tabs } from 'components/generic'

  export default
    props:
      modelValue: NLG
      intent: Object

    data: ->
      nlg: @modelValue
      variations: {}
      showVariationEditor: true

    computed:
      media: ->
        @$root.media.map (medium) ->
          {id: medium, name: medium, image: Globals.channelTypeLogos[medium.toUpperCase()]}

    watch:
      modelValue: ->
        @nlg = @modelValue
      '$root.currentLanguage': -> @changeLanguage()
      '$root.currentLanguageVariation': -> @changeLanguage()

    created: ->
      @setVariations()

    methods:
      changeLanguage: ->
        @showVariationEditor = false
        @$nextTick =>
          @setVariations()
          @showVariationEditor = true
      setVariations: ->
        @variations = Object.fromEntries(
          @$root.media.map (medium) =>
            [medium, @nlg.forLanguage(@$root.currentLanguage).forMediumAndVariation(medium, @$root.currentLanguageVariation)]
        )
      mediumValid: (medium) ->
        @variationFor(medium).messages.every (message) -> message.valid
      variationFor: (medium) ->
        @nlg.forLanguage(@$root.currentLanguage).forMediumAndVariation(medium, @$root.currentLanguageVariation)
      update: ->
        @$emit('update:modelValue', @nlg)

    components:
      MessageVariationEditor: MessageVariationEditor
      Tabs: Tabs
