import BotbuilderApi from 'models/botbuilder_api.coffee'
import EntitySchemaAttribute from 'models/entity_schema_attribute.coffee'
import Synonym from 'models/synonym.coffee'
import ValueType from 'models/value_type.coffee'

export default class ContentEntity
  @load: (language, schemas, key, {noAlert} = {}) ->
    BotbuilderApi.getEntity(language, key, noAlert: noAlert)
      .then (entity) =>
        entity.setEntitySchema(schemas)
        entity
  @list: (language, schemas, params = {}) ->
    BotbuilderApi.getEntities(language, params)
      .then (data) ->
        data.items.forEach (entity) => entity.setEntitySchema(schemas)
        data.items

  constructor: (language, data) ->
    @language = language
    @schemaKey = data.schemaKey
    @key = ''
    @label = ''
    @description = ''
    @synonyms = []
    @attributes = {}
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @key = data.key if data.key?
    @label = data.label if data.label?
    @description = data.description  if data.description?
    @timeUpdated = data.timeUpdated if data.timeUpdated?
    if data.synonyms?
      @synonyms = data.synonyms.map (synonymData) -> new Synonym(synonymData)
    @attributes = data.attributes if data.attributes?
    this

  create: ->
    BotbuilderApi.createEntity(@language, @)
      .then (data) => @update(data)

  save: ->
    BotbuilderApi.updateEntity(@language, @)

  delete: ->
    BotbuilderApi.deleteEntity(@)

  setEntitySchema: (schemas) ->
    @entitySchema = (schemas || []).find (schema) => schema.key == @schemaKey

  Object.defineProperties @prototype,
    timeUpdatedFormatted:
      get: ->
        return '' if !@timeUpdated?
        moment(@timeUpdated).format(t('formats.dateTime'))
    schemaLabel:
      get: ->
        @entitySchema?.label || @schemaKey
    export:
      get: ->
        schemaKey: @schemaKey
        key: @key
        label: @label
        description: @description
        timeUpdated: @timeUpdated
        synonyms: @synonyms.map (synonym) -> synonym.export
        attributes: @attributes
