
  import Condition from 'models/condition'
  import KnowledgeSource from 'models/knowledge_source'
  import KnowledgeSourceBaseUrl from 'models/knowledge_source_base_url'
  import Project from 'models/project'

  import BaseUrlEditor from './base_url_editor.vue'
  import ConditionEditor from 'components/botbuilder/condition_editor.vue'
  import StatusIcon from './status_icon.vue'
  import { Button, EnvironmentBadge, H3, H4, Input, Label, Switch } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { PlusIcon, RefreshIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: KnowledgeSource

    data: ->
      source: @modelValue
      allLanguages: true
      languages: Object.fromEntries(Object.keys(@$root.languages).map (code) -> [code, false])
      environments: Object.fromEntries(Object.keys(Project.ENVIRONMENTS).map (env) -> [env.key, false])
      reload: false
      Project: Project
      PlusIcon: PlusIcon
      RefreshIcon: RefreshIcon

    created: ->
      @setListAttributes()

    methods:
      setListAttributes: ->
        @allLanguages = @source.languages.length == 0
        @source.languages.forEach (code) => @languages[code] = true
        @source.environments.forEach (env) => @environments[env] = true
      updateLanguages: ->
        @source.languages = Object.entries(@languages).map(([key, val]) -> key if val).compact()
        @allLanguages = @source.languages.length == 0
        @update()
      updateAllLanguages: ->
        if @allLanguages
          Object.keys(@languages).forEach (code) => @languages[code] = false
        else
          Object.keys(@languages).forEach (code) => @languages[code] = true
        @updateLanguages()
      updateEnvironments: (key, val) ->
        switch key
          when 'DEV'
            if val == false
              @environments.TEST = false
              @environments.LIVE = false
          when 'TEST'
            if val == true
              @environments.DEV = true
            else if val == false
              @environments.LIVE = false
          when 'LIVE'
            if val == true
              @environments.DEV = true
              @environments.TEST = true
        @source.environments = Object.entries(@environments).map(([key, val]) -> key if val).compact()
        @update()
      recrawl: ->
        @reload = true
        @source.recrawl()
          .then(@poll)
      addBaseUrl: ->
        @source.baseUrls.push(new KnowledgeSourceBaseUrl(@source))
      removeBaseUrl: (index) ->
        @source.baseUrls.splice(index, 1)
        @update()
      addCondition: ->
        @source.activeIf = new Condition()
      removeCondition: ->
        @source.activeIf = null
        @update()
      update: ->
        @$emit('update:modelValue', @source)

    components:
      BaseUrlEditor: BaseUrlEditor
      Button: Button
      ConditionEditor: ConditionEditor
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      EnvironmentBadge: EnvironmentBadge
      H3: H3
      H4: H4
      Input: Input
      Label: Label
      RefreshIcon: RefreshIcon
      StatusIcon: StatusIcon
      Switch: Switch
