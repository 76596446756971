
  import Button from './button.vue'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      title: String
      subtitle: String
      cta: String

    data: ->
      PlusIcon: PlusIcon

    components:
      Button: Button
