
  import BotbuilderApi from 'models/botbuilder_api'
  import KnowledgeSource from 'models/knowledge_source'
  import Project from 'models/project'
  import TableConfiguration from 'models/table_configuration'
  import { confirm } from 'helpers'
  import { availableLanguages } from 'rails_data'

  import SourceCreatorModal from './source_creator_modal.vue'
  import StatusIcon from './status_icon.vue'
  import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
  import Wrapper from './wrapper.vue'
  import { DocumentTextIcon, FolderIcon, GlobeAltIcon, PlusIcon, XIcon } from '@heroicons/vue/outline'
  import { ActionMenu, Api2PaginationWithNumbers, Button, DocumentationLink, EmptyState, EnvironmentBadge, H3, Input, MultiselectDropdown, PageHeader, Spinner, TableConfigurator, TileList } from 'components/generic'

  export default
    props:
      userSettings: Object

    data: ->
      filters:
        text: ''
        languages: []
        environments: []
      tableConfig: new TableConfiguration(
        columns: [
          {key: 'description', label: 'Description', defaultShow: true}
          {key: 'canonical', label: 'User-facing label', defaultShow: false}
          {key: 'status', label: 'Status', defaultShow: true}
          {key: 'languages', label: 'Languages', defaultShow: false}
          {key: 'environments', label: 'Environments', defaultShow: false}
          {key: 'docInfo', label: 'Documents info', defaultShow: true}
          {key: 'lastUpdated', label: 'Last updated', defaultShow: true}
        ]
      )
      pollingTimeouts: {}
      debounceTimeout: null
      availableLanguages: availableLanguages
      mounted: false
      PlusIcon: PlusIcon
      KnowledgeSource: KnowledgeSource
      languageOptions: Object.entries(@$root.languages).map ([code, lang]) -> {key: code, label: lang}
      environmentOptions: Project.ENVIRONMENTS

    computed:
      filtersForPagination: ->
        text: @filters.text
        languages: @filters.languages.map((lang) -> lang.key).join(',')
        environments: @filters.environments.map((env) -> env.key).join(',')
        types: if @$root.isSuper then 'http_crawler,documents,content' else null
      mayAddSource: ->
        return true if !@mounted
        Globals.restrictions.knowledge_sources > @$refs.pagination.total
      noSources: ->
        @mounted && !@$refs.pagination.loading && !@$refs.pagination.items.length

    created: ->
      @initUserSettings()

    mounted: ->
      @mounted = true
      @loadSources()

    unmounted: ->
      @clearPollingTimouts()

    methods:
      initUserSettings: ->
        @userSettings.knowledgeSourceList ||= {}
        @userSettings.knowledgeSourceList.columns ||= {}
        @tableConfig.columns.forEach (col) =>
          @tableConfig.columnsByKey[col.key].show = @userSettings.knowledgeSourceList.columns[col.key] if @userSettings.knowledgeSourceList.columns[col.key]?
      loadSources: ->
        @$refs.pagination.load(page: 0)
      pollSources: ->
        @clearPollingTimouts()
        @$refs.pagination.items.forEach (source) => @updateAndPoll(source)
      clearPollingTimouts: ->
        Object.values(@pollingTimeouts).forEach (timeout) -> clearTimeout(timeout)
        @pollingTimeouts = {}
      goTo: (source) ->
        @$router.push(name: 'knowledgeSource', params: {sourceId: source.id})
      openCreator: ->
        @$refs.creator.open()
      updateAndPoll: (source, data) ->
        source.updateStatusModel(data)
        return if !source.id
        @pollingTimeouts[source.id] = setTimeout((=> @poll(source)), 3000) if source.inProgress
      poll: (source) ->
        return if !source.id
        BotbuilderApi.getKnowledgeSourceStatus(source.id)
          .then (data) => @updateAndPoll(source, data)
      saveUserSettings: ->
        @tableConfig.columns.forEach (col) =>
          @userSettings.knowledgeSourceList.columns[col.key] = @tableConfig.columnsByKey[col.key].show
        @$emit('settings-changed')
      actionMenuItems: (source, index) ->
        [
          {label: 'Delete', icon: XIcon, method: => @deleteSource(source, index)}
        ]
      deleteSource: (source, index) ->
        return unless await confirm("Do you really want do delete the knowledge source “#{source.label}”?")
        source.delete()
          .then => @$refs.pagination.remove(index)

    components:
      ActionMenu: ActionMenu
      Api2PaginationWithNumbers: Api2PaginationWithNumbers
      Button: Button
      DocumentationLink: DocumentationLink
      DocumentTextIcon: DocumentTextIcon
      EmptyState: EmptyState
      EnvironmentBadge: EnvironmentBadge
      FolderIcon: FolderIcon
      GlobeAltIcon: GlobeAltIcon
      H3: H3
      Input: Input
      MultiselectDropdown: MultiselectDropdown
      PageHeader: PageHeader
      PlusIcon: PlusIcon
      SourceCreatorModal: SourceCreatorModal
      Spinner: Spinner
      StatusIcon: StatusIcon
      TableConfigurator: TableConfigurator
      TileList: TileList
      UpgradePrompt: UpgradePrompt
      Wrapper: Wrapper
