import { createApp } from 'vue_shims'
import { createWebHistory, createRouter } from 'vue-router'

import GuidGenerator from 'models/guid_generator.coffee'
import BotbuilderApi from 'models/botbuilder_api.coffee'
import Bot from 'models/bot.coffee'
import Channel from 'models/channel.coffee'
import DialogNode from 'models/dialog_node.coffee'
import Hook from 'models/hook.coffee'
import PlatformApi from 'models/platform_api'
import ContextParameterHelper from 'mixins/context_parameter_helper'
import { trackRouting } from 'helpers'

import SidebarMenu from 'components/shared/sidebar_menu.vue'
import MenuTitle from 'components/botbuilder/menu_title.vue'
import { H3 } from 'components/generic'

import AiSearchEditor from 'components/botbuilder/content/ai_search_editor.vue'
import AiSearchList from 'components/botbuilder/content/ai_search_list.vue'
import Content from 'components/botbuilder/content/content.vue'
import ContextList from 'components/botbuilder/context_parameters/context_parameter_list.vue'
import ContextParameterCreator from 'components/botbuilder/context_parameters/context_parameter_creator.vue'
import ContextParameterDetail from 'components/botbuilder/context_parameters/context_parameter_detail.vue'
import CoreBehaviorList from 'components/botbuilder/modules/core_behavior_list.vue'
import DialogTestEditor from 'components/botbuilder/dialog_tests/editor.vue'
import DialogTestList from 'components/botbuilder/dialog_tests/list.vue'
import EntityEditor from 'components/botbuilder/content/entity_editor.vue'
import FollowUpEditor from 'components/botbuilder/knowledge/follow_up_editor.vue'
import FollowUpList from 'components/botbuilder/knowledge/follow_up_list.vue'
import ModuleEditor from 'components/botbuilder/modules/module_editor.vue'
import ModuleList from 'components/botbuilder/modules/module_list.vue'
import HookList from 'components/botbuilder/hook_list.vue'
import HookEditor from 'components/botbuilder/hook_editor.vue'
import KnowledgeExplorer from 'components/botbuilder/understanding/knowledge_explorer.vue'
import KnowledgeSettings from 'components/botbuilder/knowledge/global_settings.vue'
import KnowledgeSourceList from 'components/botbuilder/knowledge/source_list.vue'
import KnowledgeSourceEditor from 'components/botbuilder/knowledge/source_editor.vue'
import NoParseMessages from 'components/botbuilder/no_parse_messages/index.vue'
import NlpExplorer from 'components/botbuilder/understanding/nlp_explorer.vue'
import SchemaAttributeEditor from 'components/botbuilder/content/schema_attribute_editor.vue'
import SchemaEditor from 'components/botbuilder/content/schema_editor.vue'
import SchemaList from 'components/botbuilder/content/schema_list.vue'
import TrainingData from 'components/botbuilder/training_data/main.vue'
import IntegrationCreator from 'components/botbuilder/integrations/integration_creator.vue'
import IntegrationList from 'components/botbuilder/integrations/integration_list.vue'
import IntegrationEditor from 'components/botbuilder/integrations/integration_editor.vue'
import Publishing from 'components/botbuilder/publishing.vue'
import SecretsList from 'components/botbuilder/secrets_list.vue'
import Hints from 'components/botbuilder/hints.vue'
import VersionList from 'components/botbuilder/versions/version_list.vue'
import Version from 'components/botbuilder/versions/version.vue'
import WorkflowCreator from 'components/botbuilder/workflows/workflow_creator.vue'
import WorkflowList from 'components/botbuilder/workflows/workflow_list.vue'
import WorkflowEditor from 'components/botbuilder/workflows/workflow_editor.vue'

initBotbuilderApp = (element) ->
  routes = [
    title: 'Chatbot authoring'
    key: 'authoring'
  ,
    name: 'root',
    path: '/',
    component: ModuleList,
    hideMenu: true,
  ,
    path: '/:botId',
    redirect: {name: 'moduleList'}
  ,
    name: 'moduleList',
    label: 'Dialog modules',
    path: '/:botId/modules',
    component: ModuleList,
  ,
    name: 'module',
    path: '/:botId/modules/:moduleKey',
    breadcrumbParent: 'moduleList',
    component: ModuleEditor,
    hideMenu: true,
  ,
    name: 'coreBehaviourList',
    label: 'Core behaviours',
    path: '/:botId/core_behaviours',
    component: CoreBehaviorList,
  ,
    name: 'coreBehaviour',
    path: '/:botId/core_behaviours/:moduleKey',
    breadcrumbParent: 'coreBehaviourList',
    component: ModuleEditor,
    hideMenu: true,
  ,
    name: 'contextParameterList',
    label: 'Context',
    path: '/:botId/context_parameters',
    component: ContextList,
  ,
    name: 'newContextParameter',
    path: '/:botId/context_parameters',
    breadcrumbParent: 'contextParameterList',
    component: ContextParameterCreator,
  ,
    name: 'contextParameter',
    path: '/:botId/context_parameters/:parameterKey',
    breadcrumbParent: 'contextParameterList',
    component: ContextParameterDetail,
  ,
    name: 'hookList',
    label: 'Hooks',
    path: '/:botId/hooks',
    component: HookList,
  ,
    name: 'newHook',
    path: '/:botId/hooks',
    breadcrumbParent: 'hookList',
    component: HookEditor,
  ,
    name: 'hook',
    path: '/:botId/hooks/:hookKey',
    breadcrumbParent: 'hookList',
    component: HookEditor,
  ,
    name: 'content'
    label: 'Content'
    badge:
      label: 'new'
      text: 'Intelligent Product Discovery: Leverage AI to allow the chatbot to interactively ask about user preferences and present tailored product options.'
    path: '/:botId/content',
    component: Content,
  ,
    name: 'contentList'
    breadcrumbParent: 'content'
    path: '/:botId/content/:schemaKey',
    component: Content,
  ,
    name: 'contentEntity'
    path: '/:botId/content/:schemaKey/:entityKey',
    component: EntityEditor,
  ,
    name: 'entitySchemaList'
    path: '/:botId/content_schema',
    component: SchemaList,
  ,
    name: 'entitySchema'
    path: '/:botId/content_schema/:schemaKey',
    component: SchemaEditor,
  ,
    name: 'entitySchemaAttribute'
    path: '/:botId/content_schema/:schemaKey/:attributeKey',
    component: SchemaAttributeEditor,
  ,
    name: 'aiSearchList'
    path: '/:botId/ai_search',
    component: AiSearchList,
  ,
    name: 'aiSearch'
    path: '/:botId/ai_search/:aiSearchId',
    component: AiSearchEditor,
  ,
    name: 'knowledge',
    label: 'Knowledge',
    badge:
      label: 'new'
      text: 'Automatically send tailored follow-up messages when the chatbot uses particular knowledge sources in responses.'
    path: '/:botId/knowledge',
    component: KnowledgeSourceList,
  ,
    name: 'knowledgeSource',
    path: '/:botId/knowledge/:sourceId',
    component: KnowledgeSourceEditor,
  ,
    name: 'followUpList',
    path: '/:botId/follow_ups',
    component: FollowUpList,
  ,
    name: 'knowledgeSettings',
    path: '/:botId/knowledge_settings',
    component: KnowledgeSettings,
  ,
    name: 'followUp',
    path: '/:botId/follow_ups/:followUpId',
    component: FollowUpEditor,
  ,
    title: 'Integrations & workflows'
    key: 'integrationsAndWorkflows'
  ,
    name: 'integrationList',
    label: 'Integrations',
    path: '/:botId/integrations',
    component: IntegrationList,
  ,
    name: 'newIntegration',
    breadcrumbParent: 'integrationList',
    path: '/:botId/integrations',
    component: IntegrationCreator,
  ,
    name: 'integration',
    breadcrumbParent: 'integrationList',
    path: '/:botId/integrations/:integrationId',
    component: IntegrationEditor,
  ,
    name: 'workflowList',
    label: 'Workflows',
    path: '/:botId/workflows',
    component: WorkflowList,
  ,
    name: 'newWorkflow',
    breadcrumbParent: 'workflowList',
    path: '/:botId/workflows',
    component: WorkflowCreator,
  ,
    name: 'workflow',
    breadcrumbParent: 'workflowList',
    path: '/:botId/workflows/:workflowId',
    component: WorkflowEditor,
  ,
    name: 'secrets',
    label: 'Secrets',
    path: '/:botId/secrets',
    component: SecretsList,
  ,
    title: 'Natural language understanding'
    key: 'nlu'
  ,
    name: 'trainingData',
    label: 'Training data',
    path: '/:botId/training_data/:tab?',
    component: TrainingData,
  ,
    name: 'noParseMessages',
    label: 'Training opportunities',
    path: '/:botId/training_opportunities',
    component: NoParseMessages,
  ,
    name: 'nlpExplorer',
    label: 'Understanding',
    path: '/:botId/nlp_explorer',
    component: NlpExplorer,
  ,
    name: 'knowledgeExplorer',
    path: '/:botId/knowledge_explorer',
    component: KnowledgeExplorer,
  ,
    title: 'Publishing & quality'
    key: 'publishingAndQuality'
  ,
    name: 'publishing',
    label: 'Publishing',
    path: '/:botId/publishing',
    component: Publishing,
  ,
    name: 'hints',
    label: 'Hints & errors',
    path: '/:botId/hints',
    component: Hints,
  ,
    name: 'testList',
    label: 'Dialog tests',
    path: '/:botId/tests',
    component: DialogTestList,
    restricted: !Globals.restrictions.dialog_tests,
  ,
    name: 'test',
    breadcrumbParent: 'testList',
    path: '/:botId/tests/:testId',
    component: DialogTestEditor,
  ,
    name: 'versionList',
    path: '/:botId/versions',
    component: VersionList,
  ,
    name: 'version',
    path: '/:botId/versions/:changeId',
    component: Version,
  ].map (item) -> Object.assign(item, path: '/projects/:projectId/:stageLevel/automation' + item.path)

  router = createRouter(
    history: createWebHistory()
    routes: routes
  )
  trackRouting(router)

  createApp(
    mixins: [ContextParameterHelper]

    data: ->
      media = Channel.TYPES
        .map (type) -> type.key
        .concat(['PHONE'])
      elementData = JSON.parse(element.getAttribute('data'))

      bots: []
      contextParameters: []
      activeBot: null
      currentBot: null
      currentModule: null
      workflows: []
      entitySchemas: []
      menuItems: @$router.options.routes.filter (route) -> route.label? || route.title?
      languages: elementData.languages
      currentLanguage: Object.keys(elementData.languages)[0]
      languageVariations: elementData.language_variations
      currentLanguageVariation: 'DEFAULT'
      media: media
      currentMedium: media[0]
      sessionId: GuidGenerator.newUUID()
      userSettings: elementData.settings || {}
      isSuper: elementData.super
      isVWFS: elementData.vwfs
      contentKnowledgeAvailable: elementData.content_knowledge_available
      showDebugInfo: false
      messages: []
      appData: {}
      debounceTimeout: null

    computed:
      currentRoute: ->
        return {} unless @$route.name?
        @$router.options.routes.find (route) => route.name == @$route.name
      displayRouterView: ->
        @currentBot?
      contextParameterCategories: ->
        Array.unique(
          @contextParameters.map (cp) -> cp.category
        )
          .filter (cat) -> cat?.length
          .sort (a, b) -> a.localeCompare(b)

    watch:
      '$route.params.botId': -> @setCurrentBot()
      '$route.name': (newRouteName, oldRouteName) ->
        @setBreadcrumbs()
        if !oldRouteName?
          @$nextTick => @setMenuHeight()
      '$route.params.moduleKey': -> @setBreadcrumbs()
      'currentRoute.hideMenu': ->
        if !@currentRoute.hideMenu
          @$nextTick => @setMenuHeight()
      bots: -> @setCurrentBot()

    created: ->
      window.breadcrumbs.append(
        text: 'Automation'
        callback: => @$router.push(name: 'moduleList')
        rails: true
      )
      @initUserSettings()
      @loadBots()
      @loadContextParameters()
      @loadSchemas()
      @loadWorkflows()

    mounted: ->
      @setMenuHeight()
      window.addEventListener('resize', @setMenuHeight)

    unmounted: ->
      window.removeEventListener('resize', @setMenuHeight)

    methods:
      setMenuHeight: ->
        return if !@$refs.menu
        menu = @$refs.menu.$el
        menu.style.height = document.documentElement.clientHeight - menu.getBoundingClientRect().top + 'px'
      setCurrentBot: ({reset} = {reset: false}) ->
        if !@$route.params.botId || reset
          @$router.push(name: 'moduleList', params: {botId: @activeBot.id})
          return
        currentBot = if @$route.params.botId == 'active_bot'
          @activeBot
        else
          @bots.find (bot) => bot.id == @$route.params.botId
        return if !currentBot?
        currentBot.loadConfig()
          .then => @currentBot = currentBot
      goToTarget: (target) ->
        if target instanceof DialogNode
          @$router.push(name: 'module', params: {moduleKey: target.moduleKey}, query: {edit_node: target.key})
        else if target instanceof Hook
          @$router.push(name: 'hook', params: {hookKey: target.key})
        else if target instanceof Object && target.moduleKey? && target.nodeKey?
          @$router.push(name: 'module', params: {moduleKey: target.moduleKey}, query: {edit_node: target.nodeKey})
      loadBots: ->
        BotbuilderApi.getBots().then (bots) =>
          if bots.length == 0
            @createBot()
          else
            @setActiveBot(bots).finally =>
              @bots = bots
      setActiveBot: (bots) ->
        Bot.active(bots: bots)
          .then (activeBot) => @activeBot = activeBot
      loadContextParameters: ->
        BotbuilderApi.getContextParameters().then (cps) => @contextParameters = cps
      loadWorkflows: ->
        BotbuilderApi.getWorkflows().then (workflows) => @workflows = workflows
      loadSchemas: ->
        BotbuilderApi.getEntitySchemas()
          .then (schemas) => @entitySchemas = schemas.sortByString('label')
      createBot: ->
        bot = new Bot(label: Globals.project.name)
        bot.save().then =>
          @loadBots().then =>
            @$router.push(name: 'moduleList', params: {botId: bot.id})
      removeBot: (bot) ->
        @bots = @bots.filter (b) => b.id != bot.id
        @setCurrentBot(reset: true)
      setBreadcrumbs: ->
        if @currentRoute.breadcrumbParent?
          parentRoute = @$router.options.routes.find (route) => route.name == @currentRoute.breadcrumbParent
          window.breadcrumbs.strip().append(
            text: parentRoute.label
            callback: => @$router.push(name: parentRoute.name)
          )
        else
          window.breadcrumbs.strip().append(text: @currentRoute.label)
      initUserSettings: ->
        @userSettings.menu ||= {}
        @userSettings.menu.authoring = true if !@userSettings.menu.authoring?
        @userSettings.menu.integrationsAndWorkflows = false if !@userSettings.menu.integrationsAndWorkflows?
        @userSettings.menu.nlu = false if !@userSettings.menu.nlu?
        @userSettings.menu.publishingAndQuality = false if !@userSettings.menu.publishingAndQuality?
      saveSettings: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(=>
          PlatformApi.Webapp.saveProjectSettingsForAllEnvironments(Globals.projectId, automation: @userSettings)
        , 1000
        )

    components:
      H3: H3
      MenuTitle: MenuTitle
      SidebarMenu: SidebarMenu

    template: '
      <div class="bg-white flex w-full">
        <SidebarMenu
          v-if="currentRoute.name && !currentRoute.hideMenu"
          :items="menuItems"
          :params="{botId: $route.params.botId}"
          :settings="userSettings.menu"
          @settings-changed="saveSettings"
          ref="menu"
          class="tw-hidden md:flex md:w-64 md:flex-shrink-0 md:fixed"
        >
          <template #title>
            <MenuTitle v-if="isSuper" :current-bot="currentBot" :bots="bots"/>
            <H3 v-else>{{ currentBot && currentBot.label || "&nbsp;" }}</H3>
          </template>
        </SidebarMenu>

        <div :class="[currentRoute.hideMenu ? \'w-full\' : \'md:ml-64 md:w-[calc(100%-16rem)]\', \'flex-1 flex\']">
          <router-view v-if="displayRouterView" v-slot="{ Component }">
            <component
              :is="Component"
              ref="view"
              :bot="currentBot"
              :user-settings="userSettings"
              @remove="removeBot"
              @settings-changed="saveSettings"
            />
          </router-view>
        </div>
      </div>
    '
  )
    .use(router)
    .mount(element)


export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('automation-app')
      initBotbuilderApp(element)
